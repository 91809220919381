import React, { useEffect } from 'react';

const IASBadge = () => {
  useEffect(() => {
    // Create a script element for loading the external script
    const script = document.createElement('script');
    script.src = "https://www-cdn.icef.com/scripts/iasbadgeid.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      console.log('IAS Badge script loaded');
      const badgeContainer = document.getElementById('iasBadge');
      if (badgeContainer) {
        const certNum = badgeContainer.getAttribute('data-account-id');
        console.log('cert:', certNum);
        if (!certNum) {
          console.error('IAS Badge: Cert ID is missing');
          return;
        }
        const url = 'https://icef.com/scripts/iasbadge.php?certId=' + certNum;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if (!Array.isArray(data.records)) {
              console.warn('Not an IAS Agent');
              return;
            }
            if (!data.records[0].CDN_link_to_IAS_logo__c) {
              console.warn('No IAS Badge found');
              return;
            }
            badgeContainer.innerHTML = `<a href="https://www.icef.com/agency/${data.records[0].Master_Account__c}" target="_blank"><img src="${data.records[0].CDN_link_to_IAS_logo__c}" alt="logo" style="width: 160px;"></a>`;
        })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    };

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <span id="iasBadge" data-account-id="5396"></span>
  );
};

export default IASBadge;
