import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const SingleTeam = ({ name, title,icon,id }) => {
  return (
    <>
      {/* <div className="col-xl-3 col-lg-4 col-md-6">
        <div className="team__item p-relative text-center fix mb-30 d-flex flex-column">
          <div className="team__thumb mb-25">
            <div className="team__info text-start">
              <h3>
                <Link to="/teamDetails">{name}</Link>
              </h3>
              <span>{title}</span>
            </div>
          </div>
          <div className="team__content flex-grow-1">
            <h1>
              <Link to="/teamDetails">{name}</Link>
            </h1>
            <h3>{title}</h3>
          </div>
          <div className="features__btn-2 mt-auto">
            <Link to="/about" className="link-btn">
              <i className="big-arrow">
                <CgArrowLongRight />
              </i>
              <i className="big-arrow">
                <CgArrowLongRight />
              </i>
            </Link>
          </div>
        </div>
      </div> */}
      <div className="col-xl-4 col-lg-2 col-md-4 col-sm-4">
            <div className="features__item text-center">
               <div className="features__thumb" style={{ background:`url(assets/img/features/features-1.jpg)`,backgroundRepeat:'no-repeat',width:'100%',backgroundPosition:'center',backgroundSize:'cover'}}
              ></div>
               <div className="features__content">
                  <div className="features__icon">
                     <i> {icon} </i>
                  </div>
                  <h3> {name} </h3>
                  <h3> {title} </h3>
                  <div className="features__btn">
                     <Link to={`/servicesDetails/${id}`} className="link-btn">
                        <i > <CgArrowLongRight /> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
    </>
  );
};

export default SingleTeam;
