import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BlogLeftSideBar from "../../Blogs/BlogsArea/BlogLeftSideBar";
import apiService from "../../../global/common/environment";

const BlogDetailsArea = () => {
  const params = useParams();
  const { id } = params;
  const [blogData, setBlogData] = useState({});
  const base = "https://api.partners.makestudy.com:8443/uploads/blogs/";
  const base2 = "https://api.partners.makestudy.com:8443/uploads/blogs/null";
  const defaultBlogImage =
    "https://content.vu.edu.au/sites/default/files/student-creating-study-plan.jpg";

  useEffect(() => {
    const getBlog = async () => {
      const response = await axios.get(
       apiService.getBlogDetails(id)
      );
      console.log(response.data.data);
      setBlogData(response.data.data);
    };
    getBlog();
  }, [id]);

  return (
    <section className="blog__area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="blog__content col-xl-8 col-lg-8">
            <div className="blog__image">
              <img
                src={
                base==blogData.featured_image || base2==blogData.featured_image ? defaultBlogImage : blogData.featured_image 
                }
                alt="Blog Image"
                width="100%"
                loading="lazy"
              />
            </div>
            <div
              className="blog__description"
              dangerouslySetInnerHTML={{ __html: blogData.description }}
            />
          </div>

          <BlogLeftSideBar />
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsArea;
