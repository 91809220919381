import React, { useEffect, useState } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminNavBar from "./components/AdminNavBar/AdminNavBar";
import AdminSideNav from "./components/AdminSideNav/AdminSideNav";
import "./AdminMain.scss"; // Assuming you'll use SCSS or CSS for styling
import Loading from "../components/shared/Loading/Loading";

const AdminMain = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [navigation.state]);

  return (
    <div className="admin-layout">
      <ToastContainer />
      <div style={{ position: "sticky", top: "0" ,zIndex:'999',width:'100%'}}>
            <AdminNavBar />
          </div>
      <div className="admin-body">
      <AdminSideNav
          isOpen={isSidebarOpen}
          onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        />
           <div
          className={` ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"} mt-5`}
          style={{ width: "100%" }}
        >
          <main>{isLoading ? <Loading /> : <Outlet />}</main>
        </div>
      </div>
    </div>
  );
};

export default AdminMain;
