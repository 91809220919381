import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify"; // Import Toast
import "react-toastify/dist/ReactToastify.css"; // Import Toast styles
import "./OtpDialog.scss"; 
import apiService from "../../global/common/environment";

const OtpDialog = ({ open, onClose, formData }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpTimer, setOtptimer] = useState(60);
  const navigate = useNavigate();

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
    setOtp(newOtp);
    if (newOtp[index] && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const payload = { otpCode: otp.join(""), email: formData.email };
    
    try {
      const response = await axios.post(apiService.verifyOtp, payload);
      // http://partners.makestudy.com/api/users/verify-otp

      if (response.status === 201 && response.data.message) {
        setOtp(["", "", "", "", "", ""]); 
        onClose();
        navigate('/PasswordPage', { state: { email: formData.email, otpCode: otp.join("") } });
        toast.success("OTP verified successfully!"); // Toast success
      } else if (response.status === 400) {
        toast.error("Invalid OTP"); // Toast error
      }
    } catch (error) {
      console.error("OTP verification failed: ", error);
      toast.error("Invalid OTP"); // Toast error
    }
  };

  return (
    <>
      <Modal show={open} onHide={onClose} centered className="otp-dialog">
        <Modal.Header closeButton className="otp-header">
          <Modal.Title>Let's verify your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="text-center">
              <Col>
                <p>We want you to have a secure experience</p>
                <div className="otp-instructions">Enter the 6-digit OTP Code</div>
              </Col>
            </Row>
            <Form onSubmit={handleOtpSubmit} className="otp-form">
              <Row className="justify-content-center otp-inputs">
                {otp.map((value, index) => (
                  <Col xs={2} key={index} className="otp-col">
                    <Form.Control
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleOtpChange(e, index)}
                      className="otp-input-box text-center"
                      required
                    />
                  </Col>
                ))}
              </Row>
              <Row className="mt-4">
                <Col className="text-center">
                  <Button type="submit" className="verify-button">
                    Verify OTP
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row className="mt-3">
              <Col className="text-center otp-resend">
                Don't have OTP?{" "}
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Resend now
                </a>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* <ToastContainer />  */}
    </>
  );
};

export default OtpDialog;
