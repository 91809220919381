import React from 'react';
import './Terms.scss';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Agreement</h1>
      <h2>
        This Agreement ("Agreement") is entered into by and between MakeStudy, hereinafter referred to
        as the "Consultant", and Business Associate, hereinafter referred to as the "Agent", collectively referred
        to as the "Parties"
      </h2>

      <div className="terms-list">
        <h2 id="standards">1. Standards and Ethics</h2>
        <p>The Agent agrees to adhere to the highest standards and ethics in all recruitment activities.</p>

        <h2 id="licensing">2. Licensing Requirement</h2>
        <p>The Agent warrants that it is a licensed agency duly authorized by the respective government authorities.</p>

        <h2 id="compliance">3. Compliance with Immigration Instructions</h2>
        <p>The Agent shall thoroughly review and understand the immigration instructions provided on the official
          websites of the respective countries: Canada, United Kingdom, Australia, New Zealand, United States, and Europe.</p>

        <h2 id="no-visa-guarantee">4. No Guarantee on Visa</h2>
        <p>The Agent shall refrain from making any promises or guarantees regarding visa issuance.</p>

        <h2 id="document-authentication">5. Document Authentication</h2>
        <p>The Agent shall verify the authenticity of submitted documents and any related information, including past
          refusals and travel history.</p>

        <h2 id="admission-fee-policies">6. Admission and Fee Policies</h2>
        <p>The Agent shall carefully review the admission and tuition fee payment/refund policies of colleges/universities/schools.
          The agent understands that the application fee paid to college/university is non-refundable.</p>

        <h2 id="dispute-resolution-fees">7. Dispute Resolution</h2>
        <p>The Agent shall be responsible for resolving any disputes related to non-refundable application fees or service fees charged by them.</p>

        <h2 id="timely-updates">8. Timely Updates</h2>
        <p>The Agent shall promptly update the Principal on the application or visa status of the students.</p>

        <h2 id="refund-understanding">9. Refund Understanding & Processing</h2>
        <p>In the event of visa refusal, the Agent shall promptly initiate and process tuition fee refunds.
          The Agent understands that it is their responsibility to check the refund policy of the college/university.
          The Agent must inform the Principal immediately about any visa refusal to the principal and provide all
          the necessary documents to process the refund from the college/university.</p>

        <h2 id="non-refundable-tuition">10. Non-refundable Tuition Fee</h2>
        <p>The Agent acknowledges that tuition fees are non-refundable in the event of application withdrawal,
          subject to the policies of each college.</p>

        <h2 id="commission-payment">11. Commission Payment Amount</h2>
        <p>The Principal agrees to pay the Agent a commission as outlined in the commission annexure which
          will be shared after the agreement. Any changes to the commission will be communicated to the Agent
          via email. Additionally, the Principal will notify the Agent of any commission updates for each application
          processed in every email, as per our policy, to avoid any confusion or conflict later.</p>

        <h2 id="commission-release">12. Commission Release</h2>
        <p>The Principal agrees to release the Agent's commission share within 30 days of receipt of commission
          from abroad colleges/agencies.</p>

        <h2 id="no-commission-withdrawal">13. No Commission for Withdrawal/Non-Attendance</h2>
        <p>The Agent understands that no commission will be paid in cases where the student withdraws or does not attend classes.</p>

        <h2 id="taxation">14. Taxation</h2>
        <p>The Agent acknowledges that commissions received from overseas colleges or agencies are subject to applicable
          taxes based on the Agent's country of registration. For agencies registered in India, a Goods and Services Tax
          (GST) of 18% will apply. For Canadian agencies, a HST of 5% will be applicable.</p>

        <h2 id="dispute-resolution-agreement">15. Dispute Resolution</h2>
        <p>Any disputes arising under this Agreement shall be governed and resolved in accordance with the laws of
          Jalandhar Jurisdiction, Punjab, India.</p>

        <h2 id="relationship">16. Relationship of the Parties</h2>
        <p>The relationship between MakeStudy and the Agent is that of a principal and independent service provider.
          The Agent is not a legal partner, subsidiary, employee, or representative of MakeStudy, and shall not
          represent itself as such under any circumstances.</p>
      </div>
    </div>
  );
};

export default Terms;
