import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AllContext from "./context/AllContext";
import "react-modal-video/scss/modal-video.scss";
import "react-image-lightbox/style.css";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "semantic-ui-css/semantic.min.css";
import 'animate.css';
import { RouterProvider } from "react-router-dom";
import router from "./Router/routes";

ReactDOM.render(
  <React.Fragment>
    <AllContext>
       <RouterProvider router={router}/>
    </AllContext>
  </React.Fragment>,
  document.getElementById("root")
);
