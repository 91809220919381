import React from 'react'
import PageHelmet from '../shared/PageHelmet'
import HomeOneHeader from '../../pages/Home/HomeOneHeader/HomeOneHeader'
import CommonPageHeader from '../CommonPageHeader/CommonPageHeader'
import Setpasswordarea from './Setpasswordarea'
import CommonCtaArea from '../CommonCtaArea/CommonCtaArea'
import BlogFooter from '../../pages/Blogs/BlogFooter/BlogFooter'

const Setpassword = () => {
  return (
    <>
         <PageHelmet pageTitle="Make study" />

<HomeOneHeader/>
<CommonPageHeader  />
<Setpasswordarea/>

      

    </>
 
  )
}

export default Setpassword
