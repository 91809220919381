import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";

const SinglePortfolio = ({ portfolio, index, images }) => {
  const {
    img,
    title,
    description,
    programName,
    programImage,
    programDescription,
    slug,
  } = portfolio;

  const [photoIndex, setPhotoIndex] = useState(index);
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      {/* All cards will have the same layout without conditional class names */}
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
        <div className="portfolio__item p-relative mb-30">
          <div className="portfolio__thumb w-img fix">
            <img
              src={images}
              alt={title}
              style={{ objectFit: "cover", imageRendering: "auto" }}
            />
            <div className="portfolio__overlay">
              <h3>
                <Link to="/programs">{portfolio.programName}</Link>
              </h3>
            </div>
          </div>
          <div className="portfolio__info text-start">
            <h1>{programName}</h1>
            <p dangerouslySetInnerHTML={{ __html: programDescription }}></p>
            <a href="/programs">
              <button className="z-btn">Explore more</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePortfolio;
