import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { Button, Modal } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import "./AddProgram.scss";
import apiService from "../../../../../global/common/environment";
import { collages } from "../../../../../global/common/data";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import Swal from 'sweetalert2';


// Define toolbar options for Quill editor
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];


const AddPrograms = ({ mode, editData }) => {
  const params = useParams();
  const editId = params.id || "";
  const nav = useNavigate();

  const [formData, setFormData] = useState({
    programId: "",
    courseName: "",
    files: [],
    collageName: "",
    courseDescription: "",
    courseLength: "",
    credentials: "",
    availableIntakes: "",
    openIntakes: "",
    applicationFee: "",
    LOAdeposit: "",
    annualTuitionFee: "",
    commissionAmount: "",
    commissionCurrency: "",
    published: "",
    duration: "",
    courseDetails: "",
    courseRequirements: "",
    languageRequirements: "",
    careerOpportunities: "",
    courseHighlights: "",
  });

  const [errors, setErrors] = useState({});
  const sortedOrderCollages = collages.sort((a, b) => {
    if (a["College Name"] < b["College Name"]) return -1;
    if (a["College Name"] > b["College Name"]) return 1;
    return 0;
  });

  const [image, setImage] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState("");

  const fields = [
    {
      label: "Course Name",
      name: "courseName",
      type: "text",
      placeholder: "Enter course name",
    },
    {
      label: "Collage Name",
      name: "collageName",
      type: "select",
      options: sortedOrderCollages,
      optionLabel: "College Name",
    },
    {
      label: "Course Description",
      name: "courseDescription",
      type: "text",
      placeholder: "Enter course description",
    },
    {
      label: "Course Length",
      name: "courseLength",
      type: "text",
      placeholder: "Enter course length",
    },
    {
      label: "Credentials",
      name: "credentials",
      type: "text",
      placeholder: "Enter credentials",
    },
    {
      label: "Available Intakes",
      name: "availableIntakes",
      type: "text",
      placeholder: "Enter available intakes",
    },
    {
      label: "Open Intakes",
      name: "openIntakes",
      type: "text",
      placeholder: "Enter open intakes",
    },
    {
      label: "Application Fee",
      name: "applicationFee",
      type: "text",
      placeholder: "Enter application fee",
    },
    {
      label: "LOA Deposit",
      name: "LOAdeposit",
      type: "text",
      placeholder: "Enter LOA deposit",
    },
    {
      label: "Annual Tuition Fee",
      name: "annualTuitionFee",
      type: "text",
      placeholder: "Enter annual tuition fee",
    },
    {
      label: "Commission Amount",
      name: "commissionAmount",
      type: "text",
      placeholder: "Enter commission amount",
    },
    {
      label: "Commission Currency",
      name: "commissionCurrency",
      type: "text",
      placeholder: "Enter commission currency",
    },
    {
      label: "Published",
      name: "published",
      type: "select",
      options: ["Y", "N"],
      placeholder: "",
    },
    {
      label: "Duration",
      name: "duration",
      type: "text",
      placeholder: "Enter duration",
    },
  ];

  const editorFields = [
    "courseDetails",
    "courseRequirements",
    "languageRequirements",
    "careerOpportunities",
    "courseHighlights",
  ];

  const editorFieldLabels = {
    courseDetails: "Course Details",
    courseRequirements: "Course Requirements",
    languageRequirements: "Language Requirements",
    careerOpportunities: "Career Opportunities",
    courseHighlights: "Course Highlights",
  };

  const getPrograms = async () => {
    const response = await axios.get(apiService.getPrograms);
    if (response.data) {
      setPrograms(response.data);
    }
  };

  const getProgram = async () => {
    if (editId) {
      const response = await axios.get(
        apiService.getProgramCourseDetails(editId)
      );
      setFormData(response.data.data);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setFormData({ ...formData, files: e.target.files[0] });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    fields.forEach(({ name }) => {
      if (!formData[name]) {
        newErrors[name] = `${name.replace(/([A-Z])/g, " $1")} is required.`;
      }
    });
    if (!formData.programId) {
      newErrors.programId = "Program ID is required.";
    }
    if (!image && !editId) {
      alert("Please select an image.");
      newErrors.image = "Image is required.";
    }
    if(editId && !image) {
     delete  newErrors.image 

    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateFields()) return;

  //   const dataToSubmit = new FormData();
  //   Object.keys(formData).forEach((key) => {
  //     dataToSubmit.append(key, formData[key]);
  //   });
  //   try {
  //     const response = await axios.post(
  //       apiService.createProgramCourse,
  //       dataToSubmit
  //     );
  //     if (response.data) {
  //       nav("/admin/displayprograms");
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  // const handleEditSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateFields()) return;

  //   const dataToSubmit = new FormData();
  //   Object.keys(formData).forEach((key) => {
  //     dataToSubmit.append(key, formData[key]);
  //     if (key === "programId") {
  //       dataToSubmit.delete(key);
  //       dataToSubmit.append(key, formData.programId._id);
  //       dataToSubmit.append("files", image);
  //     }
  //   });
  //   try {
  //     const response = await axios.put(
  //       apiService.updateProgramCourse(editId),
  //       dataToSubmit
  //     );
  //     if (response.data) {
  //       nav("/admin/displayprograms");
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
  
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You are about to add a new program course.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, add it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (confirm.isConfirmed) {
      const dataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        dataToSubmit.append(key, formData[key]);
      });
  
      try {
        const response = await axios.post(apiService.createProgramCourse, dataToSubmit);
        if (response.data) {
          nav("/admin/displayprograms");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
  
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You are about to update the program course.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (confirm.isConfirmed) {
      const dataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        dataToSubmit.append(key, formData[key]);
        if (key === "programId") {
          dataToSubmit.delete(key);
          dataToSubmit.append(key, formData.programId._id);
          dataToSubmit.append("files", image);
        }
      });
  
      try {
        const response = await axios.put(apiService.updateProgramCourse(editId), dataToSubmit);
        if (response.data) {
          nav("/admin/displayprograms");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  
  const openModal = (field) => {
    setCurrentField(field);
    setIsModalOpen(true);
  };

  const saveModalContent = (content) => {
    setFormData({ ...formData, [currentField]: content });
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getPrograms();
    getProgram();
  }, [editId]);

  return (
    <div className="add-programs-container mt-5">
      <h1>{editId ? "Edit Program Course" : "Add Program Course"}</h1>
      <form encType="multipart/form-data" className="add-programs-form">
        <div className="add-programs-row">
          <div className="add-programs-col-md-12 mb-3">
          <div className="add-programs-col-md-12 mb-3">
            <label htmlFor="programId" className="form-label">
              {editId ? "Edit Program" : "Add Program"}
            </label>
            <select
              className="form-select"
              id="programId"
              name="programId"
              value={formData.programId}
              onChange={handleChange}
            >
              <option value={""}>
                {" "}
                {formData.programId.programName
                  ? formData.programId.programName
                  : "Select Program "}
              </option>
              {programs.map((program) => (
                <option key={program._id} value={program._id}>
                  {program.programName}
                </option>
              ))}
            </select>
          </div>
            {errors.programId && (
              <div className="invalid-feedback">{errors.programId}</div>
            )}
          </div>

          {fields.map(({ label, name, type, options, optionLabel }) => (
            <div key={name} className="add-programs-col-md-6 mb-3">
              <label htmlFor={name} className="form-label">
                {label}
              </label>
              {type === "select" ? (
                <select
                  className={`form-select ${errors[name] ? "is-invalid" : ""}`}
                  id={name}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {options.map((option) => (
                    <option key={option.id} value={option[optionLabel]}>
                      {option[optionLabel] || option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={type}
                  className={`form-control ${errors[name] ? "is-invalid" : ""}`}
                  id={name}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                />
              )}
              {errors[name] && (
                <div className="invalid-feedback">{errors[name]}</div>
              )}
            </div>
          ))}

          <div className="add-programs-col-md-6 mb-3">
            {image ? (
              <img
                src={image}
                alt="Course"
                className="add-programs-course-image"
                style={{ height: "50px", width: "50px" }}
              />
            ) : (
              formData.courseImage && (
                <img
                  src={formData.courseImage}
                  alt="Course"
                  className="add-programs-course-image"
                  style={{ height: "50px", width: "50px" }}
                />
              )
            )}
            <label htmlFor="files" className="form-label">
              Upload Image
            </label>
            <input
              type="file"
              className="form-control"
              id="files"
              name="files"
              onChange={handleImageChange}
              required={editId ? false : true}
            />
            {errors["image"] && (
              <div className="text-danger">{errors["image"]}</div>
            )}
          </div>

          {editorFields.map((field) => (
            <div key={field} className="add-programs-col-md-12 mb-3">
              <label className="form-label">{editorFieldLabels[field]}
                {/* {field
                  .replace(/([A-Z])/g, " ")
                  .replace(/^./, (str) => str.toUpperCase())} */}
              </label>
              <Button
                variant="link"
                onClick={() => openModal(field)}
                className="edit-button"
              >
                <FaEdit />
              </Button>
            </div>
          ))}
        </div>

        <Button
          type="submit"
          className="z-btn "
          onClick={!editId ? handleSubmit : handleEditSubmit}
        >
          {editId ? "Update Program Course" : "Add Program Course"}
        </Button>
      </form>

      <Modal show={isModalOpen} onHide={closeModal} className="custom-modal">
  <Modal.Header closeButton>
    <Modal.Title>
      Add{" "}
      {currentField
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())}
    </Modal.Title>
  </Modal.Header>
  
  <ReactQuill
    className="custom-react-quill"
    theme="snow"
    value={formData[currentField] || ""}
    onChange={(value) => {
      setFormData((prevData) => ({
        ...prevData,
        [currentField]: value,
      }));
    }}
    modules={{ toolbar: toolbarOptions }}
  />
     

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => saveModalContent(formData[currentField])}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPrograms;
