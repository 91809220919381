import React from "react";
import { AiOutlineMail, AiOutlineLock } from "react-icons/ai";
import { HiEye, HiEyeOff } from "react-icons/hi";
import axios from "axios";
import { Button, Image, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "../../global/common/environment";
import { useNavigate } from "react-router-dom";

const LoginDialog = ({ open, onClose }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let formErrors = {};

    if (!email) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email address is invalid.";
    }

    if (!password) {
      formErrors.password = "Password is required.";
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(apiService.userLogin, { email, password });
      const data = response.data;

      if (data.message !== "Invalid credentials") {
        toast.success("Login successful!");
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("userBasicInfo", JSON.stringify(data.basicUserData));

        if (!data.isAgentInfoFilled) {
          navigate("/agentpage");
        } else if (data.isAgentInfoFilled) {
          onClose();
          navigate("/agent");
        } else {
          onClose();
        }
      }
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(apiService.reSetPassword, { email });
      const data = response.data;

      if (data.message === "OTP sent successfully") {
        toast.success("OTP sent to your email!");
        navigate("/forgotpassword", { state: { email } });
        onClose();
      } else {
        toast.error(data.message || "Error sending OTP.");
      }
    } catch (error) {
      toast.error("An error occurred while sending OTP.");
    }
  };

  return (
    <>
      <Modal show={open} onHide={onClose} dialogClassName="modal-95w" size="lg" className="modal-custom">
        <Modal.Body>
          <button type="button" className="btn-close position-absolute top-0 end-0 m-3" aria-label="Close" onClick={onClose}></button>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-12 col-md-6 d-md-block mb-4 mb-md-0">
              <Image src="assets/img/Webimg/courseimg/signup368552(1).jpg" alt="Sample" fluid className="rounded-start login-image" />
            </div>
            <div className="col-12 col-md-6">
              <div className="p-4 text-black">
                <h1>Hi, Welcome Back.</h1>
                <p className="small-info mb-4">Login and explore, unlocking new opportunities for growth</p>

                {/* Email Field with Icon */}
                <div className="form-outline mb-3 position-relative">
                  <AiOutlineMail className="input-icon position-absolute start-0 top-50 translate-middle-y ms-3" />
                  <input
                    type="email"
                    className="form-control form-control-lg ps-5"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && <div className="error-message">{errors.email}</div>}
                </div>

                {/* Password Field with Icon */}
                <div className="form-outline mb-3 position-relative">
                  <AiOutlineLock className="input-icon position-absolute start-0 top-50 translate-middle-y ms-3" />
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-lg ps-5"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div onClick={handleTogglePasswordVisibility} className="password-toggle position-absolute end-0 top-50 translate-middle-y me-3">
                    {showPassword ? <HiEyeOff /> : <HiEye />}
                  </div>
                  {errors.password && <div className="error-message">{errors.password}</div>}
                </div>

                {/* Remember Me and Forgot Password */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="rememberMe" />
                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                  </div>
                  <p onClick={handleForgotPassword} className="text-muted">Forgot password?</p>
                </div>

                {/* Login Button */}
                <div className="d-flex justify-content-end pt-3">
                  <Button type="button" className="z-btn" onClick={handleSubmit} disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                  </Button>
                </div>

                {/* Register Link */}
                <div className="mt-4 text-center">
                  <p>Don't have an account? <a href="/register">Register here</a></p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default LoginDialog;
