import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import TeamDetailsArea from './TeamDetailsArea/TeamDetailsArea';
import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import HomeThreeFaq from '../HomeThree/HomeThreeFaq/HomeThreeFaq';

const TeamDetails = () => {
   return (
      <>
         <PageHelmet pageTitle="FAQ Page" />

         <HomeOneHeader/>
         <CommonPageHeader title="FAQ Page" subtitle="Faq Page" />
         {/* <TeamDetailsArea/>
         <CommonCtaArea/> */}
         <HomeThreeFaq/>
        <BlogFooter/>
      </>
   );
};

export default TeamDetails;