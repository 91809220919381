import React from "react";
import "./admin.nav.scss";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AdminNavBar = () => {
  const nav=useNavigate()
  const handleLogout = () => {
    localStorage.clear();
    nav('/admin-login')
  }
  return (
    <div>
      <nav className="p-4 w-100" style={{ backgroundColor: "black" }}>
        <div className="text-light fs-2 top-nav">
          <Dropdown>
            <Dropdown.Toggle 
              variant="dark" 
              id="dropdown-basic" 
              className="bg-dark"
            >
              <CgProfile />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '200px', padding: '0', margin: '0' }}>
              <Dropdown.Item href="#/profile" style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
                <CgProfile style={{ marginRight: '8px' }} />
                Profile
              </Dropdown.Item>
              <Dropdown.Item  style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }} onClick={()=>handleLogout()}>
                <FiLogOut style={{ marginRight: '8px' }} />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </div>
  );
};

export default AdminNavBar;
