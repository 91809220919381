import React, { useState, forwardRef } from "react"; // Ensure useState is imported
import { Link } from "react-router-dom";


import Button from "react-bootstrap/Button"; // Assuming you're using a Button component from a library
import SignupModal from "../../../components/Forms/SignupModal";

const HomeTwoAchievement = forwardRef((props, ref) => {
  const [isScrolled, setIsScrolled] = useState(false); // Assuming you're using this for scroll styling
  const [openModal, setOpenModal] = useState(false); // State for modal visibility

  const handleOpenModal = () => setOpenModal(true);  // Open modal
  const handleCloseModal = () => setOpenModal(false); // Close modal

  return (
    <>
      <section className="achievement__area pt-120" ref={ref}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="achievement__content">
                <div className="section__title section__title-3 fade-up ">
                  <h2>Expand Your Business with Us</h2>
                </div>
                <br />
                <br />
                <p>
                  Partner with MakeStudy and watch your business thrive. By
                  signing up, you'll gain access to a wealth of benefits and
                  resources tailored to support your growth. Our team will
                  connect you with a dedicated in-market advisor who will
                  provide personalized guidance and answer any questions you
                  might have.<br/> Benefit from our extensive network, exclusive
                  opportunities, and expert insights to help you navigate the
                  market and maximize your potential. Join us today and leverage
                  our expertise to propel your business forward.
                </p>
                <Button
                  className={`z-btn  ${isScrolled ? "scrolled-button" : ""}`}
                  onClick={handleOpenModal} // Open modal on button click
                >
                  patner with us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Render SignupModal and pass the necessary props */}
      <SignupModal open={openModal} onClose={handleCloseModal} />
    </>
  );
});

export default HomeTwoAchievement;
