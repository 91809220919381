import React from "react";
import { Link } from "react-router-dom";
import "./TeamLeftBar.scss"; // Import the CSS file

const TeamLeftBar = () => {
  return (
    <div className="col-12 col-sm-4 col-md-3 col-lg-2">
      <div className="course__sidebar">
        {/* FAQ Section */}
        <section className="sidebar__faq">
          {/* FAQ Widget - General Question */}
          <div className="widget">
            <h5 className="widget__title">Do you have any question?</h5>
            <p>We help you with the process Through our best team.</p>
            <Link to="/about" className="z-btn small-btn">
  Contact Us
</Link>
            <br/>
            <img
              src="/assets/img/Webimg/Shutterstock.jpg"
              alt="Shutterstock"
              className="sidebar__image"
            />
          </div>
          <br />
          <br/>

          {/* FAQ Accordion Section */}
          <div className="faq__accordion">
            <h3 className="widget__title">FAQ</h3>
            <div className="accordion" id="accordionExample">
              {/* FAQ Item 1 */}
              <div className="card accordion-item">
                <div className="card-header accordion-header" id="acc_1">
                  <p className="mb-0">
                    <button
                      className="btn btn-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_1"
                      aria-expanded="true"
                      aria-controls="collapse_1"
                    >
                      What services do you offer?
                    </button>
                  </p>
                </div>

                <div
                  id="collapse_1"
                  className="collapse show"
                  aria-labelledby="acc_1"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body accordion-body">
                    <p className="ptag">
                      We provide expert admission services to help students
                      secure spots in prestigious colleges and universities.
                    </p>
                  </div>
                </div>
              </div>

              {/* FAQ Item 2 */}
              <div className="card accordion-item">
                <div className="card-header accordion-header" id="acc_2">
                  <h6 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_2"
                      aria-expanded="false"
                      aria-controls="collapse_2"
                    >
                      Where are your colleges located in Canada?
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse_2"
                  className="collapse"
                  aria-labelledby="acc_2"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body accordion-body">
                    <p>
                      Our partner colleges are located across major cities and
                      provinces in Canada, including Ontario, British Columbia,
                      and Quebec.
                    </p>
                  </div>
                </div>
              </div>

              {/* FAQ Item 3 */}
              <div className="card accordion-item">
                <div className="card-header accordion-header" id="acc_3">
                  <h6 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_3"
                      aria-expanded="false"
                      aria-controls="collapse_3"
                    >
                      Are your partner colleges in Canada eligible for a
                      Post-Graduation Work Permit (PGWP)?
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse_3"
                  className="collapse"
                  aria-labelledby="acc_3"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body accordion-body">
                    <p>
                      Yes, programs offered through our partner colleges in
                      Canada are eligible for a PGWP.
                    </p>
                  </div>
                </div>
              </div>

              {/* FAQ Item 4 */}
              <div className="card accordion-item">
                <div className="card-header accordion-header" id="acc_4">
                  <h6 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_4"
                      aria-expanded="false"
                      aria-controls="collapse_4"
                    >
                      What is the lowest tuition fee at your partner colleges?
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse_4"
                  className="collapse"
                  aria-labelledby="acc_4"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body accordion-body">
                    <p>
                      Our yearly tuition fees start as low as $10,000 CAD for
                      master's degree programs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TeamLeftBar;
