import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEye } from "react-icons/fa"; // Import the eye icon for viewing details
import "./AgentDisplay.scss"; // Include your styling here
import apiService from "../../../global/common/environment";

const AgentDisplay = () => {
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null); // State to hold selected agent details
    const [showDetails, setShowDetails] = useState(false); // State to control modal visibility

    const getAllAgents = async () => {
        try {
            const res = await axios.get(apiService.getAllagentsInfo);
            setAgents(res.data);
            console.log(res.data, "agents");
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllAgents();
    }, []);

    const changeStatus = async (id, status) => {
        try {
            const res = await axios.put(apiService.updateAgentInfo(id), { verified: status });
            console.log(res.data);
            getAllAgents();
        } catch (err) {
            console.log(err);
        }
    };

    const handleViewDetails = (agent) => {
        setSelectedAgent(agent);
        setShowDetails(true); // Show the modal
    };

    const handleCloseModal = () => {
        setShowDetails(false);
        setSelectedAgent(null);
    };

    return (
        <div className="admin-dashboard-container">
            <h1 className="dashboard-title">Agents</h1>
            <table className="admin-dashboard-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Agency</th>
                        <th>Designation</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Phone</th>
                        <th>No. of Branches</th>
                        <th>Person Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.length > 0 ? (
                        agents.map((agent, index) => (
                            <tr key={index} className={`agent-row ${agent.verified || ""}`}>
                                <td>{agent?.agentId?.first_name || "N/A"}</td>
                                <td>{agent?.agentId?.last_name || "N/A"}</td>
                                <td>{agent?.agentId?.email || "N/A"}</td>
                                <td>{agent?.organizationName || "N/A"}</td>
                                <td>{agent?.contactPersonInformation?.designation || "N/A"}</td>
                                <td>{agent?.country || "N/A"}</td>
                                <td>{agent?.city || "N/A"}</td>
                                <td>{agent?.agentId?.mobile_no || "N/A"}</td>
                                <td>{agent?.noOfBranches || "N/A"}</td>
                                <td>{agent?.contactPersonInformation?.personName || "N/A"}</td>
                                <td>
                                    <span className={`status-badge ${agent?.verified === "active" ? "active" : "inactive"}`}>
                                        {agent?.verified ? agent.verified.charAt(0).toUpperCase() + agent.verified.slice(1) : "N/A"}
                                    </span>
                                </td>
                                <td>
                                    <button className="toggle-status-btn" onClick={() => changeStatus(agent._id, agent?.verified === "active" ? "inactive" : "active")}>
                                        {agent?.verified === "active" ? "inactive" : "active"}
                                    </button>
                                    <button className="view-details-btn" onClick={() => handleViewDetails(agent)}>
                                        <FaEye />
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="12">No agents found.</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {showDetails && selectedAgent && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <h2>Agent Details</h2>
                        <p><strong>First Name:</strong> {selectedAgent?.agentId?.first_name || "N/A"}</p>
                        <p><strong>Last Name:</strong> {selectedAgent?.agentId?.last_name || "N/A"}</p>
                        <p><strong>Email:</strong> {selectedAgent?.agentId?.email || "N/A"}</p>
                        <p><strong>Agency:</strong> {selectedAgent?.organizationName || "N/A"}</p>
                        <p><strong>Designation:</strong> {selectedAgent?.contactPersonInformation?.designation || "N/A"}</p>
                        <p><strong>Country:</strong> {selectedAgent?.country || "N/A"}</p>
                        <p><strong>City:</strong> {selectedAgent?.city || "N/A"}</p>
                        <p><strong>Phone:</strong> {selectedAgent?.agentId?.mobile_no || "N/A"}</p>
                        <p><strong>No. of Branches:</strong> {selectedAgent?.noOfBranches || "N/A"}</p>
                        <p><strong>Contact Person Name:</strong> {selectedAgent?.contactPersonInformation?.personName || "N/A"}</p>
                        <p><strong>Status:</strong> {selectedAgent?.verified || "N/A"}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AgentDisplay;
