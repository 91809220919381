import React from "react";
import { forwardRef } from 'react';

const features = [
  { title: "Find Programs", imgSrc: "/assets/img/icons/examine.png" },
  {
    title: " Easy Application",
    imgSrc: "/assets/img/icons/app-development.png",
  },
  {
    title: "Central Platform",
    imgSrc: "/assets/img/icons/responsive-design.png",
  },
  { title: "Gain Success", imgSrc: "/assets/img/icons/success.png" },
  {
    title: " Support Team",
    imgSrc: "/assets/img/icons/relationship.png",
  },
];

const HomeCounterArea = forwardRef((props, ref) => {
  return (
    <section className="features__area features__inner pb-100" ref={ref} >
      <div className="brand__shape">
        <img
          className="square"
          src="/assets/img/icon/brand/square.png"
          alt="Square shape"
        />
        <img
          className="circle"
          src="/assets/img/icon/brand/circle.png"
          alt="Circle shape"
        />
        <img
          className="circle-2"
          src="/assets/img/icon/brand/circle-2.png"
          alt="Circle 2 shape"
        />
        <img
          className="triangle"
          src="/assets/img/icon/brand/trianlge.png"
          alt=""
        />
      </div>
      <div className="container">
        <div className="text-center mb-5  fade-up">
          <h1 className="fade-up" >
            Empowering Agents, Transforming futures.
          </h1>
          <br />
          <p className="p-title" >
            Our platform simplifies the application process, making it easier
            for agents to assist students with their admissions. Enjoy a
            seamless, user-friendly experience designed to enhance your
            efficiency and support your students effectively.
          </p>
        </div>
        <br />
        <div className="white-bg wow fadeInUp" data-wow-delay=".2s">
          <div className="row justify-content-center">
            {features.map((feature, index) => (
              <div
                key={index}
                className="col-lg-2 col-md-4 col-sm-6 text-center"
              >
                <div className="feature__item">
                  <div className="feature__icon mb-3">
                    <img
                      src={feature.imgSrc}
                      alt={feature.title}
                   
                    />
                  </div>
                  <h3
                    className="feature__title"
                   
                  >
                    {feature.title}
                  </h3>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <button className="z-btn fade-up">Try It Today</button>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HomeCounterArea;
