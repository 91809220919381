import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loading from "../../components/shared/Loading/Loading";

const AdminGuard = ({ component }) => {
  const [access, setAccess] = useState(null); // null indicates loading state

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setAccess(false);
      localStorage.clear();

      return;
    }

    try {
      const decode = jwtDecode(token);
      if (decode.exp < Date.now() / 1000) {
        setAccess(false);
      } else if (decode.exp > Date.now() / 1000 && decode.role === "admin") {
        setAccess(true);
      } else {
        setAccess(false);
        localStorage.clear();
      }
    } catch (error) {
      // Handle token decoding error
      localStorage.clear();
      setAccess(false);
    }
  }, []);

  // Show loading state while checking access
  if (access === null) {
    return <div><Loading/></div>; // Or a spinner
  }

  return access ? component : <Navigate to="/admin-login" />;
};

export default AdminGuard;
