import React, { Component, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { HiUser, HiMail, HiPhone, HiOfficeBuilding } from "react-icons/hi";
import axios from "axios";
import OtpDialog from "./OtpDialog"; // Ensure this path is correct
import { ToastContainer, toast } from "react-toastify"; // Import Toast
import "react-toastify/dist/ReactToastify.css"; // Import Toast styles
import "./SignupModal.scss"; // Ensure this path is correct
import apiService from "../../global/common/environment";

const SignupModal = ({ open, onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [Data, setData] = useState();

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!email) newErrors.email = "Email is required";
    if (!phone) newErrors.phone = "Phone is required";
    if (!companyName) newErrors.companyName = "Company name is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
useEffect(()=>{
// window.scrollTo(0, 0);
},[open])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile_no: phone,
      company: companyName,
      verified: "inactive",
    };
    setData(formData);
    const clearForm = () => {
      setEmail("");
      setCompanyName("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setLastName("");
    };
    try {
      const response = await axios.post(apiService.userRegister, formData);
      const dataForCache = JSON.stringify(formData);
      localStorage.setItem("userCache", dataForCache);
      console.log(response);
      if (response.data.message === "OTP sent successfully") {
        setIsOtpOpen(true);
        onClose();
        clearForm();
        toast.success("OTP sent successfully!"); // Toast success
      } else {
        toast.error(`Signup failed: ${response.data.message}`); // Toast error
        clearForm();
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error(error.response.data.message); // Toast error
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={onClose}
        size="xl"
        aria-labelledby="signup-modal-title"
        centered
        className="signup-modal"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            aria-label="Close"
            onClick={onClose}
          ></button>

          <div className="row g-0">
            <div className="col-lg-6 d-none d-lg-block">
              <img
                src="assets/img/Webimg/courseimg/signup368552.jpg"
                alt="Sample"
                className="img-fluid modal-image"
              />
            </div>

            <div className="col-lg-6 col-12">
              <div className="card-body p-md-5 text-black">
                {/* <h1 className="mb-3 text-uppercase fw-bold">Sign Up</h1> */}
                <h1>
                  Register as a <a href="/admin">Recruitment Partner</a>
                </h1>
                <p className="small-info mb-4">
                  Fill out the form below to create your account and begin your
                  journey with us and become part of our global network.
                </p>

                <Form onSubmit={handleSubmit}>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <Form.Group className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          isInvalid={!!errors.firstName}
                          className="form-control-lg border-blue shadow ps-5"
                        />
                        <div className="input-icon position-absolute top-50 start-0 translate-middle-y ms-3">
                          <HiUser />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          isInvalid={!!errors.lastName}
                          className="form-control-lg border-blue shadow ps-5"
                        />
                        <div className="input-icon position-absolute top-50 start-0 translate-middle-y ms-3">
                          <HiUser />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <Form.Group className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          isInvalid={!!errors.companyName}
                          className="form-control-lg border-blue shadow ps-5"
                        />
                        <div className="input-icon position-absolute top-50 start-0 translate-middle-y ms-3">
                          <HiOfficeBuilding />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="position-relative">
                        <Form.Control
                          type="tel"
                          placeholder="Phone Number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          isInvalid={!!errors.phone}
                          className="form-control-lg border-blue shadow ps-5"
                        />
                        <div className="input-icon position-absolute top-50 start-0 translate-middle-y ms-3">
                          <HiPhone />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group className="mb-4 position-relative">
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!errors.email}
                      className="form-control-lg border-blue shadow ps-5"
                    />
                    <div className="input-icon position-absolute top-50 start-0 translate-middle-y ms-3">
                      <HiMail />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Check
                      type="checkbox"
                      label={
                        <>
                          I agree to the{" "}
                          <a
                            href="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            terms and conditions
                          </a>
                        </>
                      }
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      className="no-border"
                      required
                    />
                  </Form.Group>

                  <div className="text-center">
                    <Button type="submit" className="z-btn">
                      Signup
                    </Button>
                  </div>

                  <div className="text-center mt-4">
                    <span>Already have an account?</span>{" "}
                    <a href="/login">Login</a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <OtpDialog
        open={isOtpOpen}
        onClose={() => setIsOtpOpen(false)}
        formData={Data}
      />
      {/* <ToastContainer autoClose={800}   /> Add ToastContainer to your component */}
    </>
  );
};

export default SignupModal;
