import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import apiService from "../../../global/common/environment";
import ReCAPTCHA from "react-google-recaptcha";

const ContactArea = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaRef = useRef(null); // Reference for the ReCAPTCHA component

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA");
      return;
    }
    try {
      // Sending data to Brevo (Sendinblue) API
      const response = await axios.post(apiService.contactUs, {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });

      if (response.status === 201) {
        toast.success("Thank you for contacting us!");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setRecaptchaToken(null); // Clear the token
        recaptchaRef.current.reset(); // Reset the reCAPTCHA
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
      <section className="contact__area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xl-12">
              <div className="contact__map">
                <iframe
                  title="contact"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6816.345179847197!2d75.57529637621036!3d31.32660635668842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5a5edcb4248d%3A0x83cc8f0a6f1443ea!2sDeepak%20Enterprises%20(MakeStudy)!5e0!3m2!1sen!2sus!4v1725883119227!5m2!1sen!2sus"
                ></iframe>
                <div
                  className="contact__wrapper d-md-flex justify-content-between wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="contact__info mr-100">
                    <h3>Get in touch </h3>
                    <ul>
                      <li>
                        <h4>Call us</h4>
                        <p>
                          <a href="tel:+91 96581-96581">+91 96581-96581</a>
                        </p>
                        <p>
                          <a href="tel:+91 98880-98881"> +91 98880-98881</a>
                        </p>
                      </li>
                      <li>
                        <h4>Email Address</h4>
                        <p>
                          <a href="mailto:agents@makestudy.com">
                            agents@makestudy.com
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="contact__form">
                    {/* Form Submission */}
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef} // Use the ref here
                        className="recaptcha-container"
                        sitekey="6LfvV3oqAAAAAFzoSKtIsyW1g1ANxXNZGVFcztGk"
                        onChange={handleRecaptchaChange}
                      />
                      <br/>
                      <br/>
                      <button type="submit" className="z-btn" disabled={!recaptchaToken}>
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
