import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import TeamArea from './TeamArea/TeamArea';
import HomeFeatures from '../Home/HomeFeatures/HomeFeatures';
import BlogLeftSideBar from '../Blogs/BlogsArea/BlogLeftSideBar';
import HomeTwoFooter from '../HomeTwo/HomeTwoFooter/HomeTwoFooter';

const Team = () => {
   return (
      <>
         <PageHelmet pageTitle="Team Page" />

         <HomeOneHeader/>
         <CommonPageHeader title="Our Programs" subtitle="program Categories" />
         {/* <HomeFeatures/> */}
         <TeamArea/>
         <CommonCtaArea/>
         <HomeTwoFooter/>
      </>
   );
};

export default Team;