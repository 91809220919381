import React, { useEffect, useState } from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import ServicesDetailsArea from './ServicesDetailsArea/ServicesDetailsArea';
import ServicesDetailsHeader from './ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../HomeTwo/HomeTwoFooter/HomeTwoFooter';
import { useParams } from 'react-router-dom';

const ServicesDetails = () => {
   const {id}=useParams()
   console.log("this is my id ",id)
   const [courseName,setCourseName]=useState("")
   const getCourseName=(name)=>{
      setCourseName(name)
      console.log("checking name",name)
   }
   // useEffect(()=>{
   //    getCourseName()
   // },[])
   
   return (
      <>
         <PageHelmet pageTitle="Services Details Page" />

         <HomeOneHeader />
         <ServicesDetailsHeader  course={courseName}/>
         <ServicesDetailsArea  id={id}  setCourseName={getCourseName}/>
         <HomeTwoFooter/>
      </>
   );
};

export default ServicesDetails;