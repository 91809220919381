export const collages=[
    {
        "id": 1,
        "College Name": "Olds College",
        "City": "Olds",
        "Province": "Alberta",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 2,
        "College Name": "Medicine Hat College (MHC)",
        "City": "Medicine Hat",
        "Province": "Alberta",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 3,
        "College Name": "Norquest College",
        "City": "Edmonton",
        "Province": "Alberta",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 4,
        "College Name": "City University (CityU)",
        "City": "Calgary",
        "Province": "Alberta",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 5,
        "College Name": "University of Calgary (Ucal)",
        "City": "Calgary",
        "Province": "Alberta",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 6,
        "College Name": "Lasalle College vancouver",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 7,
        "College Name": "Alexander College",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 8,
        "College Name": "Coquitlam College",
        "City": "Surrey",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 9,
        "College Name": "University Canada West",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 10,
        "College Name": "Trinity western university (TWU)",
        "City": "Langlay",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 11,
        "College Name": "Justice institute of British coloumbia",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 12,
        "College Name": "Adler University",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 13,
        "College Name": "New York Institute of Technology (NYIT)",
        "City": "Vancouver",
        "Province": "British Columbia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 14,
        "College Name": "Ontario Tech University (OTU)",
        "City": "Oshawa",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 15,
        "College Name": "Niagara University (NU)",
        "City": "Vaughan",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 16,
        "College Name": "University of Niagara Falls (UNF)",
        "City": "Niagara Falls",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 17,
        "College Name": "Queens University",
        "City": "Kingston",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 18,
        "College Name": "Toronto School of Management (TSoM)",
        "City": "Toronto",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Inactive"
    },
    {
        "id": 19,
        "College Name": "Canadian college of technology & business (CCTB)",
        "City": "Vancouver",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Inactive"
    },
    {
        "id": 20,
        "College Name": "Access College Riverside",
        "City": "Saint-Hubert",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 21,
        "College Name": "Sir wilfrid Laurier School Board-CDC",
        "City": "Laval",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 22,
        "College Name": "Vanier College",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 23,
        "College Name": "Lasalle College Montreal",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 24,
        "College Name": "Canada College",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 25,
        "College Name": "Cester College",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 26,
        "College Name": "Tav College",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 27,
        "College Name": "Aviron College",
        "City": "Montreal",
        "Province": "Quebec",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 28,
        "College Name": "Southeast College (SE)",
        "City": "Wayburn",
        "Province": "Saskatchewan",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 29,
        "College Name": "University of Regina (Uregina)",
        "City": "Saskatchewan",
        "Province": "Saskatchewan",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 30,
        "College Name": "Dalhousie University",
        "City": "Halifax",
        "Province": "Nova Scotia",
        "Country": "Canada",
        "Status": "Active"
    },
    {
        "id": 31,
        "College Name": "Georgian College",
        "City": "Barrie",
        "Province": "Ontario",
        "Country": "Canada",
        "Status": "Active"
    }
  ]

