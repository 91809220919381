import React, { useState } from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoAchievement from './HomeTwoAchievement/HomeTwoAchievement';
import HomeTwoBlogs from './HomeTwoBlogs/HomeTwoBlogs';
import HomeTwoCaseArea from './HomeTwoCaseArea/HomeTwoCaseArea';
import HomeTwoCounter from './HomeTwoCounter/HomeTwoCounter';
import HomeTwoExpertArea from './HomeTwoExpertArea/HomeTwoExpertArea';
import HomeTwoFaq from './HomeTwoFaq/HomeTwoFaq';
import HomeTwoFeatures from './HomeTwoFeatures/HomeTwoFeatures';
import HomeTwoFooter from './HomeTwoFooter/HomeTwoFooter';
import HomeTwoHeader from './HomeTwoHeader/HomeTwoHeader';
import HomeTwoHeroSlider from './HomeTwoHeroSlider/HomeTwoHeroSlider';
import HomeTwoTestimonial from './HomeTwoTestimonial/HomeTwoTestimonial';
import Homeservices from './Hometwoservice/Homeservices';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import Setpassword from '../../components/Setpassword/Setpassword';
import HomeThreeServices from '../HomeThree/HomeThreeServices/HomeThreeServices';
import HomeThreeHeroSection from '../HomeThree/HomeThreeHeroSection/HomeThreeHeroSection';
import HomeContact from '../Home/HomeContact/HomeContact';
import HomeCounterArea from '../Home/HomeCounterArea/HomeCounterArea';
import HomeThreeSecondServices from '../HomeThree/HomeThreeSecondServices/HomeThreeSecondServices';
import BackToTopButton from '../BackToTopButton/BackToTopButton';
import useAnimationOnScroll from '../../hooks/useAnimationOnScroll'; // Adjust the path as needed
import Cookie from '../../components/Cookie/Cookie';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';


const HomeTwo = () => {
   const fadeUpRef = useAnimationOnScroll('fade-up');
   const loc=useLocation()
 console.log("checking ref",loc);
 const [openLoginModalProp, SetOpenLoginModalProp] =useState(false);
//  const check
 const sendPropToOpenLoginModal=(data)=>{
   SetOpenLoginModalProp(data)
   console.log("data",openLoginModalProp)
 }
 const sampleText=(data)=>{
   console.log(data)
 }
   return (
      <>
         <PageHelmet pageTitle="Home Page" />
         <HomeTwoHeader  flagToOpenModal={openLoginModalProp} flagToCloseModal={SetOpenLoginModalProp}/>
        {loc.pathname=="/" &&  <ToastContainer/>}

         <HomeTwoHeroSlider   openLoginModal={sendPropToOpenLoginModal}/>
         <HomeThreeHeroSection ref={fadeUpRef}   />
         <HomeThreeSecondServices  ref={fadeUpRef}/>
         <HomeCounterArea ref={fadeUpRef} />
        
         <HomeTwoFeatures ref={fadeUpRef} />
        
       
         <HomeThreeServices ref={fadeUpRef}  />
         {/* <Homeservices/> */}
         <HomeTwoCounter ref={fadeUpRef}  />
         <HomeTwoAchievement  ref={fadeUpRef}  temp={sampleText}/>
         {/* <HomeAbout/> */}
         {/* <HomeTwoFaq/> */}
       
       
      
         {/* <HomeTwoExpertArea/> */}
       
         {/* <HomeTwoTestimonial/>
         <HomeTwoCaseArea/> */}
         <HomeTwoBlogs  />
         <HomeContact />
        
         <HomeTwoFooter />
         <Cookie/>
      </>
   );
};

export default HomeTwo;