import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import HomeBrands from '../Home/HomeBrands/HomeBrands';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import HomeTwoAchievement from '../HomeTwo/HomeTwoAchievement/HomeTwoAchievement';
import PortfolioDetailsArea from '../PortfolioDetails/PortfolioDetailsArea/PortfolioDetailsArea';
import BlogFooter from '../Blogs/BlogFooter/BlogFooter';
import HomeThreeFaq from '../HomeThree/HomeThreeFaq/HomeThreeFaq';
import Certificate from '../../components/CertificateSlider/Certificate';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="About Page" />

         <HomeOneHeader/>
         <CommonPageHeader title="About Us" subtitle="About" />
         {/* <HomeAbout/>
         <HomeTwoFaq/> */}
         
         <PortfolioDetailsArea/>
         <Certificate/>
         <BlogFooter/>
      </>
   );
};

export default About;