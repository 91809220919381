import React from "react";
import HomeThreeSecondSingleService from "../../../components/HomeThreeSecondSingleService/HomeThreeSecondSingleService";
import { SiKnowledgebase } from "react-icons/si";
import { BsTools, BsHeart, BsBag } from "react-icons/bs";
import { forwardRef } from "react";

const HomeThreeSecondServices = forwardRef(( props,ref)=> {
  return (
    <>
      <section className="services__area-3 fade-up " ref={ref}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="section-title section__title-3 mb-70 fade-up">
                <h2>
                  We Help <br /> Clients Make Profit
                </h2>
                <p>
                  We help Agents grow and enhance their skills for long-term
                  success{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                <ul className="nav nav-pills " id="services-tab" role="tablist">
                  <li className="nav-item mb-45">
                    <a
                      className="nav-link active"
                      id="share-tab"
                      data-bs-toggle="pill"
                      href="#share"
                      role="tab"
                      aria-controls="share"
                      aria-selected="true"
                    >
                      <i>
                        {" "}
                        <SiKnowledgebase />{" "}
                      </i>
                      We unlock potential
                    </a>
                  </li>
                  <li className="nav-item mb-30 fade-up">
                    <a
                      className="nav-link"
                      id="customer-tab"
                      data-bs-toggle="pill"
                      href="#customer"
                      role="tab"
                      aria-controls="customer"
                      aria-selected="true"
                    >
                      <i>
                        <BsTools />
                      </i>
                      Application Management
                    </a>
                  </li>
                  {/* <li className="nav-item mb-30">
                              <a className="nav-link" id="social-tab" data-bs-toggle="pill" href="#social" role="tab" aria-controls="social" aria-selected="true">
                                 <i ><BsHeart/></i> Social Media
                              </a>
                           </li> */}
                  <li className="nav-item mb-30 fade-up">
                    <a
                      className="nav-link"
                      id="financial-tab"
                      data-bs-toggle="pill"
                      href="#financial"
                      role="tab"
                      aria-controls="financial"
                      aria-selected="true"
                    >
                      <i>
                        <BsBag />
                      </i>{" "}
                      Financial Benefits
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-12 fade-up">
              <div className="services__tab grey-bg-18 fade-up">
                <div className="tab-content" id="services-content">
                  <div
                    className="tab-pane fade show active"
                    id="share"
                    role="tabpanel"
                    aria-labelledby="share-tab"
                  >
                    <HomeThreeSecondSingleService
                      title="We unlock potential"
                      description="
We are a leading networking company that bridges the gap between reputed institutions worldwide and dedicated partner agents. At the heart of our mission is the belief that collaboration unlocks potential. By connecting institutions with our trusted network of partner agents, we create opportunities for growth, innovation, and success. Our platform empowers institutions to reach new markets and agents to access exclusive deals, resources, and support. Together, we open doors to endless possibilities, helping you achieve your highest potential."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="customer"
                    role="tabpanel"
                    aria-labelledby="customer-tab"
                  >
                    <HomeThreeSecondSingleService
                      title="Application Management"
                      description="Effortlessly manage the application process by uploading student documents once and applying to multiple programs. Our platform provides real-time tracking of application documents and statuses, offering you exceptional ease and efficiency. Simplify your workload with automated updates, secure document storage, and streamlined communication with institutions. Whether you're handling a few applications or many, our system ensures a smooth and organized experience, allowing you to focus on what matters most—guiding students toward their academic goals."
                    />
                  </div>
                  {/* <div className="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                              <HomeThreeSecondSingleService title="Social Media" />
                           </div> */}
                  <div
                    className="tab-pane fade"
                    id="financial"
                    role="tabpanel"
                    aria-labelledby="financial-tab"
                  >
                    <HomeThreeSecondSingleService
                      title="Rewarding Financial Benefits"
                      description="MakeStudy provides a highly rewarding financial package with no platform fees. We offer competitive commission rates, along with additional incentives and bonuses to recognize and celebrate your dedication and hard work. Our compensation structure is designed to support and enhance your earning potential, ensuring that your efforts are truly valued. With flexible payment options and performance-based rewards, you’ll find our financial benefits not only motivating but also aligned with your success. Join us and take advantage of a partnership where your achievements are generously rewarded."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default HomeThreeSecondServices;
