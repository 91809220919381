import React from "react";
import PageHelmet from "../../../components/shared/PageHelmet";
import CommonCtaArea from "../../../components/CommonCtaArea/CommonCtaArea";
import BlogFooter from "../../../pages/Blogs/BlogFooter/BlogFooter";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeOneHeader from "../../../pages/Home/HomeOneHeader/HomeOneHeader";
import AdminDailog from "./AdminDailog";
import HomeTwoHeader from "../../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader";
import HomeTwoFooter from "../../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter";
import { Outlet } from "react-router-dom";
const AdminPage = () => {
  return (
    <>
      <PageHelmet pageTitle="Adminpage" />


      <AdminDailog />
      <HomeTwoFooter/>
    </>
  );
};

export default AdminPage;
