import { useState } from 'react';
import { FaFacebookF, FaTwitter, FaPinterestP, FaPhoneAlt, FaEnvelope, FaSearch, FaLinkedinIn, FaInstagram, FaUser, FaSignOutAlt, FaYoutube } from 'react-icons/fa';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import './HomeOneHeaderN.scss'
import Swal from 'sweetalert2';
const HomeOneHeaderN = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const nav=useNavigate()
   const { stickyMenu, isAuthenticated, handleLogout } = useGlobalContext(); // Assuming useGlobalContext provides authentication and logout function
   const logOut = () => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will be logged out!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log me out!'
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          nav('/');
          Swal.fire({
            title: 'Logged Out!',
            text: 'You have been logged out successfully.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500 
          });
          
        }
      });
    };
   return (
      <>
         <header>
        <div className="header__area p-relative header__transparent">
          <div className="header__top d-none d-md-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-4">
                  <div className="header__social">
                    <ul>
                      <li><a href="https://www.facebook.com/MakeStudy1/"><i><FaFacebookF /></i></a></li>
                      <li><a href="https://www.youtube.com/channel/UCuNmwKA2FoUSV3fX6TNUnFA/videos"><i><FaYoutube /></i></a></li>
                      <li><a href="https://www.instagram.com/makestudy1/"><i><FaLinkedinIn /></i></a></li>
                      <li><a href="https://in.linkedin.com/in/deepak-kumar-makestudy"><i><FaInstagram /></i></a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-8">
                  <div className="header__info f-right">
                    <ul>
                      <li>
                        <a href="tel:(+468)-254-762-443">
                          <i><FaPhoneAlt /></i> +91 96581-96581, +91 98880-98881
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@consulting.com">
                          <i><FaEnvelope /></i> www.makestudy.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo">
                    <NavLink to="/">
                      <img src="/assets/img/Webimg/logo1.webp" alt="logo" />
                    </NavLink>
                  </div>
                  <div className="logo-gradient">
                    <NavLink to="/">
                      <img src="/assets/img/Webimg/logo1.webp" alt="logo" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu menu_wrapper_one">
                      <nav id="mobile-menu">
                        <ul>
                          {/* Navigation items */}
                        </ul>
                      </nav>
                    </div>

                    {/* Profile icon */}
                    <div className="header__profile ml-30">
                      <Link to="/profile">
                        <i><FaUser /></i>
                      </Link>
                    </div>

                    {/* Logout button with icon for mobile */}
                    <div className="header__logout ml-20">
                      <button onClick={logOut} className="logout-btn d-none d-lg-block z-btn z-btn-white">Logout</button>
                      <i className="logout-icon d-lg-none" onClick={logOut}><FaSignOutAlt /></i>
                    </div>

                    <div onClick={handleShow} className="sidebar__menu d-lg-none">
                      <div className="sidebar-toggle-btn" id="sidebar-toggle">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header__search-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <form action="#">
                    <input type="text" placeholder="Your Keywords" />
                    <button type="button"><i><FaSearch /></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="body-overlay-2"></div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};
    

export default HomeOneHeaderN;
