import React from "react";
import './notfound.scss'
const NotFound = () => {
    window.onload = function(){
        document.querySelector('.cont_principal').className= "cont_principal cont_error_active";  
          
        }
  return (
    <div className="not-found">
      <div class="cont_principal">
        <div class="cont_error">
          <h1 style={{ color:'#8A65DF'}}>Oops</h1>
          <p>The Page you're looking for isn't here.</p>
        </div>
        <div class="cont_aura_1"></div>
        <div class="cont_aura_2"></div>
      </div>
    </div>
  );
};

export default NotFound;
