import React, { useState, useEffect } from 'react';
import './Cookie.scss';

const Cookie = () => {
   const [isVisible, setIsVisible] = useState(false);

   const executeCodes = () => {
      if (document.cookie.includes("cookieBy=codinglab")) return;
      setIsVisible(true);
   };

   const handleAccept = () => {
      document.cookie = "cookieBy=codinglab; max-age=" + 60 * 60 * 24 * 30;
      setIsVisible(false);
   };

   const handleDecline = () => {
      setIsVisible(false);
   };

   useEffect(() => {
      executeCodes();
   }, []);

   return (
      isVisible && (
         <div className={`cookie-consent ${isVisible ? 'show' : ''}`}>
            <header className="cookie-consent__header">
               <i className="bx bx-cookie cookie-consent__header-icon"></i>
               <h2 className="cookie-consent__header-title">Cookies Consent</h2>
            </header>

            <div className="cookie-consent__data">
               <p>
               We use cookies to enhance your browsing experience, analyze site traffic, and improve our services. By continuing to browse, you agree to our use of cookies. For more information on how we use cookies and how you can manage them, please refer to our Cookie Policy. <a href="#">Read more...</a>
               </p>
            </div>

            <div className="cookie-consent__buttons">
               <button
                  className="cookie-consent__buttons-button cookie-consent__buttons-button--accept"
                  id="acceptBtn"
                  onClick={handleAccept}
               >
                  Accept
               </button>
               <button
                  className="cookie-consent__buttons-decline"
                  id="declineBtn"
                  onClick={handleDecline}
               >
                  Decline
               </button>
            </div>
         </div>
      )
   );
};

export default Cookie;
