import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home/Home";
import HomeTwo from "../pages/HomeTwo/HomeTwo";
import UserGuard from "./guards/UserGuard";
import Setpassword from "../components/Setpassword/Setpassword";
import ServicesDetails from "../pages/ServicesDetails/ServicesDetails";
import Portfolio from "../pages/Portfolio/Portfolio";
import Team from "../pages/Team/Team";
import TeamDetails from "../pages/TeamDetails/TeamDetails";
import Blogs from "../pages/Blogs/Blogs";
import BlogDetails from "../pages/BlogDetails/BlogDetails";
import Contact from "../pages/Contact/Contact";
import AdminPage from "../admin/components/Adminpage/AdminPage";
import AdminGuard from "./guards/AdminGuard";
import About from "../pages/About/About";
import AdminHomePage from "../admin/AdminHomePage";
import Loading from "../components/shared/Loading/Loading";
import NotFound from "../components/shared/404/NotFound";
import AddPrograms from "../admin/components/ProgramsAndCourses/Programs/AddPrograms/AddPrograms";
import AdminMain from "../admin/AdminMain";
import HomeThree from "../pages/HomeThree/HomeThree";
import ProgramTypes from "../admin/components/ProgramsAndCourses/ProgramTypes/ProgramTypes";
import ForgotPassword from "../components/Forms/ForgotPassword";
import StudentPage from "../admin/components/StudentPage/StudentPage";
import AgentDisplay from "../admin/components/AgentDisplay/AgentDisplay";
import DisplayPrograms from "../admin/components/ProgramsAndCourses/Programs/DisplayPrograms/DisplayPrograms";
import Terms from "../components/Terms/Terms";
import AdminDailog from "../admin/components/Adminpage/AdminDailog";
import Agentdashboard from "../pages/Agent/Agentdashboard";


const Router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/", // HomeTwo component is displayed on the root path
        element: <HomeTwo />,
      },
      {
        path: "home", // Relative path
        element: (
          // <UserGuard>
           
            <UserGuard  component={<Home />}/>
         
        ),
      },
      {
        path: "PasswordPage",
        element: <Setpassword />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "servicesDetails/:id",
        element: <ServicesDetails />,
      },
      {
        path: "courses",
        element: <Portfolio />,
      },
      {
        path: "programs",
        element: <Team />,
      },
      {
        path: "teamDetails",
        element: <TeamDetails />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        path: "blogDetails/:id",
        element: <BlogDetails />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path:"loading",
        element:<Loading/>
      }
      ,
      {
        path:"agentpage",
        element:<Home/>
      },
      {
        path:"three",
        element:<HomeThree/>
      },
      {
        path:"forgotpassword",
        element:<ForgotPassword/>
      },
      {
        path:"terms",
        element:<Terms/>
      },
      {
        path:"agent",
        element:<Agentdashboard/>
      },
      
    ],
  },
{
  path:"/admin-login",
  element:<AdminDailog/>
},
  {
    path: "admin",
    element:<AdminMain/>,
    children: [
      {
        path: "", 
        element: (
          <>
           <AdminGuard component={<AdminHomePage />}/>
            </>
          
        ),
      },
      {
        path: "admin-dashboard", // Relative path under /admin
        element: (
       
            <AdminGuard component={<AdminHomePage />}/>
          
        ),
      },
      {
        path:'programs/:id?',
        element:<AdminGuard component={<AddPrograms/>}/>
      },{
        path:'admin-home',
        element: <AdminGuard component={<AdminPage/>}/>

      },
      {
        path:'programtypes',
        element:<AdminGuard component={<ProgramTypes/>}/>
      },
      {
        path:"Studentpage",
        element:<AdminGuard component={<StudentPage/>}/>
      },
      {
        path:"agentdisplay",
        element:<AdminGuard component={<AgentDisplay/>}/>
      },
      {
        path:"displayprograms",
        element:<AdminGuard component={<DisplayPrograms/>}/>
      }
     
    ],
  },

  {
    path: "*",
    element: <NotFound/>,
  },
]);

export default Router;
