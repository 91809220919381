import React from 'react';
import { FaEnvelope, FaFacebookF, FaGlobeAsia, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaTwitter, FaVimeoV, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BlogFooter = () => {
   return (
      <>
         <footer>
            <div className="footer__area black-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div >
                                    <Link to="/home">
                                       <img src="/assets/img/Webimg/logo1.webp" alt=""/>
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content footer__widget-content-2">
                                 <p className="mb-30">Copyright © 2024 All Rights Reserved passion by Makestudy</p>
                                 <div className="footer__social footer__social-2 theme-social mb-30 text-white">
                                 <ul>
                                       <li>
                                          <a href="https://www.facebook.com/MakeStudy1/">
                                             <i ><FaFacebookF /></i>
                                             <i ><FaFacebookF /></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://www.youtube.com/channel/UCuNmwKA2FoUSV3fX6TNUnFA/videos">
                                             <i ><FaYoutube /> </i>
                                             <i ><FaYoutube /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://www.instagram.com/makestudy1/">
                                             <i ><FaInstagram /> </i>
                                             <i ><FaInstagram /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://in.linkedin.com/in/deepak-kumar-makestudy">
                                             <i ><FaLinkedinIn /> </i>
                                             <i ><FaLinkedinIn /> </i>
                                          </a>
                                       </li>
                                    </ul>
                                    <br/>
                                    <li>
                                    <a href="tel:(+468)-254-762-443">
                                       <i > <FaPhoneAlt/> </i>
                                       +91 96581-96581, +91 98880-98881
                                    </a>
                                 </li>
                                 <br/>
                                 <li>
                                    <a href="mailto:info@consulting.com">
                                       <i > <FaEnvelope/> </i>
                                       www.makestudy.com
                                    </a>
                                 </li>
                                 <br/>
                                 <li>
                                    <a href="mailto:info@consulting.com">
                                       <i > <FaGlobeAsia/> </i>
                                      agents@makestudy.com
                                    </a>
                                 </li>
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title text-white">
                                 <h4>Register With Us</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                    <li><a href="#">Recruiting Partners</a></li>
                                       <li><a href="#">Students Registration</a></li>
                                       <li><a href="#">Overseas Student Insurance (Canada)</a></li>
                                       <li><a href="/contact">Book Consultation</a></li>
                                       <li><a href="/contact">Book Agent Meeting</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title text-white">
                                 <h4>Resources</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                    <li><a href="/blogs">Our Blogs</a></li>
                                       <li><a href="/programs">Programs</a></li>
                                       <li><a href="/terms">Privacy Policy</a></li>
                                       <li><a href="/terms">Terms and Conditions</a></li>
                                       <li><a href="/teamdetails">Faq</a></li>
                                       <li><a href="/contact">Help desk</a></li>
                                      
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-5 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Newsletter</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__subscribe">
                                    <p className="mb-30">Subscribe to out newsletter today to receive updates on the latest news</p>
                                    <div className="footer__subscribe-form p-relative">
                                       <form action="#">
                                          <input type="email" placeholder="Email Address"/>
                                             <button type="submit">Subscribe</button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright footer__copyright-2">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-12 text-center">
                           <div className="footer__copyright-text footer__copyright-text-2">
                              <p>Copyright © 2024 All Rights Reserved  by <Link to="/home">Make Study</Link></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default BlogFooter;