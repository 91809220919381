import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import apiService from "../../../global/common/environment";

const BlogLeftSideBar = () => {
  const [blogs, setBlogs] = useState([]);
  const [itemsPerPage] = useState(5); // Number of blogs to display per page
  const [skip, setSkip] = useState(10); // Skip the first 3 blogs

  // Helper function to shuffle the blogs array6
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  
  const base = "https://api.partners.makestudy.com:8443/uploads/blogs/";
  const base2 = "https://api.partners.makestudy.com:8443/uploads/blogs/null";
  const defaultBlogImage =
    "https://content.vu.edu.au/sites/default/files/student-creating-study-plan.jpg";
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          // `http://localhost:3000/blogs?skip=${skip}&limit=${itemsPerPage}`
          apiService.getBlogs(skip,itemsPerPage)
        );
        if (response.data.statusCode === 200) {
          const allBlogs = response.data.data;
          // console.log(allBlogs); // Log the API response to check featured_image
          const blogsWithImage = allBlogs.filter((blog) => blog.featured_image);
          // Shuffle the blogs randomly
          const randomBlogs = shuffleArray(blogsWithImage);
          setBlogs(randomBlogs);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, [skip, itemsPerPage]);

  return (
    <div className="col-xl-4 col-lg-4">
      <div className="blog__sidebar">
        {/* Search Widget */}
        {/* <div className="sidebar__widget mb-50">
                    <div className="sidebar__widget-content">
                        <div className="search">
                            <form action="#">
                                <input type="text" placeholder="Search..." />
                                <button type="button">
                                    <FaSearch />
                                </button>
                            </form>
                        </div>
                    </div>
                </div> */}

        {/* Recent Blogs Widget */}
        <div className="sidebar__widget mb-75">
          <div className="sidebar__widget-title mb-50">
            <h4>RecentBlogs</h4>
          </div>
          <div className="sidebar__widget-content">
            <div className="rc-post">
              <ul>
                {blogs.map((blog, index) => (
                  <li className="d-flex mb-20" key={index}>
                    <div className="rc-thumb mr-15">
                      <Link to={`/blogDetails/${blog.id}`}>
                        {/* <img
                          src={
                            blog.featured_image
                              // Fallback image
                          }
                          alt="assets\img\expart\expart-1.jpg" // Simple alt text
                          className="blog-sidebar-thumbnail"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        /> */}
                        <img
                          src={
                            //  blog.featured_image
                            base === blog.featured_image ||
                            base2 === blog.featured_image
                              ? defaultBlogImage
                              : blog.featured_image
                          }
                              alt="assetsg"
                           // Use the blog title as alt text, or a default text
                          className="blog-sidebar-thumbnail"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      </Link>
                    </div>
                    <div className="rc-text">
                      <h6>
                        <Link to={`/blogDetails/${blog._id}`}>{blog.title}</Link>
                      </h6>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Programs Widget */}
        <div className="sidebar__widget mb-75">
          <div className="sidebar__widget-title mb-50">
            <h4>Programs</h4>
          </div>
          <div className="sidebar__widget-content">
            <div className="cat-link">
              <ul>
                <li>
                  <Link to="/blogDetails">UG Certificates</Link>
                </li>
                <li>
                  <Link to="/blogDetails">UG Diplomas</Link>
                </li>
                <li>
                  <Link to="/blogDetails">UG Bachelors</Link>
                </li>
                <li>
                  <Link to="/blogDetails">Associate Degrees</Link>
                </li>
                <li>
                  <Link to="/blogDetails">Post Diploma Programs</Link>
                </li>
                <li>
                  <Link to="/blogDetails">Master Degrees</Link>
                </li>
                <li>
                  <Link to="/blogDetails">PG Certificates</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Popular Tags Widget */}
        <div className="sidebar__widget mb-50">
          <div className="sidebar__widget-title mb-50">
            <h4>Popular Tags</h4>
          </div>
          <div className="sidebar__widget-content">
            <div className="tags">
              <Link to="/blogDetails">PG Diplomas</Link>
              <Link to="/blogDetails">Quebec DVS Programs</Link>
              <Link to="/blogDetails">UG Bachelors</Link>
              <Link to="/blogDetails">PHD/Research Programs</Link>
              <Link to="/blogDetails">Quebec DEC Programs</Link>
              <Link to="/blogDetails">Post Diploma Programs</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLeftSideBar;
