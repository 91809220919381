import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import useGlobalContext from "../../../hooks/useGlobalContext";
import SignupModal from "../../../components/Forms/SignupModal";
import LoginModal from "../../../components/Forms/LoginModal";
import { Button } from "react-bootstrap";
import "./HomeTwoHeader.scss";

const HomeTwoHeader = ({flagToOpenModal,flagToCloseModal}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  const [openModal, setOpenModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false) ;
        flagToCloseModal(false)
  }
  ;
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => {
    setOpenModal(false);
    setOpenLoginModal(false);
  };

  useEffect(() => {
  
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [flagToOpenModal]);

  useEffect(() => {
    if(flagToOpenModal){
      handleOpenModal()
      // setOpenLoginModal(true)
      // console.log("came here")
    }
  },[flagToOpenModal])


  return (
    <>
      <header>
        {/* <h1>{flagToOpenModal?"Login":"MakeStudy"}</h1> */}
        <div
          className={`header__area p-relative ${
            isScrolled ? "header__scrolled" : "header__transparent"
          }`}
        >
          <div
            id="header__sticky"
            className={`header__bottom  ${stickyMenu ? "sticky" : ""}`}
          >
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <div className="">
                  <NavLink to="/">
                    <img
                      className={`desktop-logo ${
                        isScrolled ? "scrolled-logo" : ""
                      }`}
                      src="assets/img/Webimg/logo1.webp"
                      alt="Desktop Logo"
                    />
                  </NavLink>
                  <NavLink to="/" className="d-lg-none">
                    <img
                      className="phn-logo"
                      src="/assets/img/Webimg/logo1.webp"
                      alt="Mobile Logo"
                    />
                  </NavLink>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                <div className="header__bottom-right d-flex justify-content-end align-items-center">
                  <div className="main-menu menu_wrapper_one">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                          <NavLink to="/about">About Us</NavLink>
                        </li>
                        <li>
                          <NavLink to="/courses">Courses</NavLink>
                          {/* <ul className="submenu">
                            <li>
                              <NavLink to="/courses">Courses</NavLink>
                            </li>
                            <li>
                              <NavLink to="/servicesDetails">
                                Courses Details
                              </NavLink>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <NavLink to="/blogs">Blog</NavLink>
                          {/* <ul className="submenu">
                            <li>
                              <NavLink to="/blogs">Blog</NavLink>
                            </li>
                            <li>
                              <NavLink to="/blogDetails">Blog Details</NavLink>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <NavLink to="/contact">Contact Us</NavLink>
                        </li>
                     
                        
                      </ul>
                    </nav>
                  </div>

                  <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                    <Button
                      className={`z-btn z-btn-white z-btn-white-2 ${
                        isScrolled ? "scrolled-button" : ""
                      }`}
                      onClick={handleOpenModal}
                    >
                      Signup
                    </Button>
                  </div>

                  <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                    <Button
                      className={`z-btn z-btn-white z-btn-white-2 ${
                        isScrolled ? "scrolled-button" : ""
                      }`}
                      onClick={handleOpenLoginModal}
                    >
                      Login
                    </Button>
                  </div>

                  <div onClick={handleShow} className="sidebar__menu d-lg-none">
                    <div className="sidebar-toggle-btn" id="sidebar-toggle">
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
      <SignupModal open={openModal} onClose={handleCloseModal} />
      <LoginModal open={openLoginModal} onClose={handleCloseLoginModal} />
    </>
  );
};

export default HomeTwoHeader;
