import React from "react";
import IASBadge from "../../HomeTwo/HomeTwoAchievement/IASBadge";

const PortfolioDetailsArea = () => {
  return (
    <section className="portfolio__details pt-120 pb-130">
      <div className="container">
        <div className="row">
          <div className=" ">
            <div className="portfolio__details-content mt-10">
              <div className="portfolio__meta mb-5">
                <h4>Shaping Education | Shaping Future</h4>
              </div>
              <h1>MakeStudy</h1>
              <div className="portfolio__overview mt-40">
                <h2>About Us</h2>
                <p>
                  <span >
                    At MakeStudy, we are not just a consultancy—we are
                    architects of success, dedicated to shaping brighter futures
                    for our partners and students alike. Through our carefully
                    cultivated network of top-tier 
                    across the globe, we provide access to exclusive
                    opportunities that set us apart in the competitive world of
                    international education. When you partner with MakeStudy,
                    you unlock a wealth of strategic advantages. <br />
                    From gaining privileged access to high-demand courses at
                    renowned universities to benefiting from exclusive
                    scholarship deals, we ensure that your consultancy has the
                    edge in the marketplace. Our comprehensive  to empower agents with the tools they need to
                    expand their portfolio, enhance their services, and grow
                    their business. At the heart of our mission is a commitment
                    to supporting our agents every step of the way.
                    <br /> Whether you are seeking assistance with student
                    recruitment, visa processes, or marketing, our team of
                    experts is always on hand to provide personalized guidance
                    and support We believe in building long-lasting
                    relationships that foster growth, collaboration, and shared
                    success..
                    <br /> When you join MakeStudy, you become part of an elite
                    network that is driving change and making a difference in
                    the education industry. <br />
                    Together, we are committed to elevating your consultancy to
                     to provide better opportunities
                    for your students, and positioning your business as a leader
                    in the field. <br />
                  </span>
                  <span
                    className="badge-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "20px 0",
                    }}
                  >
                    <IASBadge />
                    <h1
                      style={{
                        fontWeight: "bold",
                        marginTop: "10px",
                        marginLeft: "10px",
                        color: "grey",
                      }}
                    >
                      ICEF Accreditation
                    </h1>
                  </span>
                  <br />
                  An ICEF Accredited Agency Committed to Excellence in Education
                  Consultancy. MakeStudy proudly stands as an ICEF Accredited
                  agency, a prestigious recognition that underscores our
                  commitment to providing top-notch educational consultancy
                  services.
                  <br />
                  ICEF accreditation is a globally recognized mark of quality,
                  awarded to agencies that demonstrate the highest standards in
                  student recruitment, ethical practices, and professionalism.
                  <br />
                  What Does ICEF Accreditation Mean for You?
                  <br />
                  Being an ICEF Accredited agency means that MakeStudy adheres
                  to rigorous industry standards, ensuring transparency,
                  integrity, and exceptional service quality.
                  <br />
                  Our accreditation assures students, parents, and partner
                  institutions that they are working with a trusted and reliable
                  agency that prioritizes their needs and upholds ethical
                  practices.
                  <br />
                  Why Choose an ICEF Accredited Agency Like MakeStudy?
                  <br />
                  1. <strong>Credibility and Trust:</strong> ICEF accreditation
                  is only awarded to agencies that meet strict criteria,
                  including thorough background checks, reference verification,
                  and adherence to ethical recruitment practices. This
                  certification sets MakeStudy apart as a reliable partner in
                  your educational journey.
                  <br />
                  2. <strong>Commitment to Quality:</strong> Our accreditation
                  signifies that we are continuously improving and adapting to
                  the latest trends in the education sector. We strive to offer
                  accurate information, personalized guidance, and comprehensive
                  support to our students and partners.
                  <br />
                  3. <strong>Global Recognition:</strong> ICEF is recognized
                  worldwide, enhancing MakeStudy’s credibility on the
                  international stage. This opens doors to a wider network of
                  accredited institutions, ensuring that our clients have access
                  to a diverse range of high-quality educational opportunities.
                  <br />
                  4. <strong>Student-Centric Approach:</strong> With ICEF
                  accreditation, MakeStudy is dedicated to providing students
                  with a seamless and supportive experience, from selecting the
                  right course to assisting with visa applications and settling
                  in their new academic environment.
                  <br />
                  Partner with MakeStudy—Your Trusted ICEF Accredited Agency-EU
                  India Chamber -Indo American Chamber of Commerce.
                  <br />
                  Choosing MakeStudy means aligning with a consultancy that not
                  only meets but exceeds industry benchmarks. Our ICEF
                  accreditation is a testament to our dedication to excellence,
                  ethical conduct, and our unwavering commitment to your
                  success.
                  <br />
                <br />
                <span >
                  Let’s collaborate and unlock unparalleled growth for your
                  consultancy, pushing the boundaries of what’s possible.
                  Partner with MakeStudy today, and together,<br/> we’ll take your
                  business—and your students—to the next level of success.
                </span>
                </p>
                <br />
                <span class="founder-info">
                  <img
                    src="/assets/img/Webimg/deepaksir.jpg"
                    alt="Founder Image"
                  />
                  <p>
                  Founder and Owner of MakeStudy and A B Language School

                    Our vision: "Affordable International Education for
                    All. <br />" An alumnus of Military School and a proud Georgian, I
                    bring over two decades of experience in the student
                    recruitment industry.
                    <br /> As a seasoned Education Consultant and global
                    traveler, I am skilled in global marketing, school
                    management, and student recruitment. I hold a Bachelor's
                    degree from MD University, Ajmer, and a Master's degree in
                    the history of India, Europe, China, and Japan from Guru
                    Nanak Dev University, Amritsar, Punjab, India.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioDetailsArea;
