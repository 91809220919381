import React, { useEffect, useState } from "react";
import axios from "axios";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { FiPlay } from "react-icons/fi";
import BlogSingleItem from "../../../components/BlogSingleItem/BlogSingleItem";
import VideoPopup from "../../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../../hooks/useGlobalContext";
import BlogLeftSideBar from "./BlogLeftSideBar";
import Pagination from 'rsuite/Pagination';
import 'rsuite/Pagination/styles/index.css';
import './BlogArea.scss';
import ScrollTop from "../../../components/ScrollTop";
import apiService from "../../../global/common/environment";

const BlogsArea = () => {
  const { setIsVideoOpen } = useGlobalContext();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let itemsPerPage = 4;
  let pagesToShow = 5;

  const [numberOfBlogs, setNumberOfBlogs] = useState(0);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(numberOfBlogs / itemsPerPage)) {
      setCurrentPage(pageNumber);
      window.scrollTo(0, 500);
    }
  };

  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(Math.ceil(numberOfBlogs / itemsPerPage), startPage + pagesToShow - 1);

  const defaultBlogImage = "https://content.vu.edu.au/sites/default/files/student-creating-study-plan.jpg";
  const base = "https://api.partners.makestudy.com:8443/uploads/blogs/";
  const base2="https://api.partners.makestudy.com:8443/uploads/blogs/null"
  // https://partners.makestudy.com:8443/uploads/blogs/
  
  useEffect(() => {
    const getAllBlogs = async () => {
      const limit = itemsPerPage;
      const skip = (currentPage - 1) * itemsPerPage; // Correct calculation for skip
      const response = await axios.get(apiService.getBlogs(limit,skip))
      setBlogs(response.data.data);
      setNumberOfBlogs(response.data.count);
    };
    getAllBlogs();
  }, [currentPage]);

  return (
    <>
      <VideoPopup videoId="yJg-Y5byMMw" />
{/* </> */}
      <section className="blog__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="blog__wrapper mr-50">
                {blogs.length > 0 ? (
                  blogs.map((blog) => (
                    <BlogSingleItem
                      key={blog._id}
                      id={blog._id}
                      image={base===blog.featured_image || base2===blog.featured_image ? defaultBlogImage: blog.featured_image}
                      user_image={blog.user_image}
                      name={blog.name}
                      title={blog.title}
                      description={blog.description}
                      video_icon={
                        blog.has_video ? (
                          <div className="blog__play p-absolute">
                            <button
                              onClick={() => setIsVideoOpen(true)}
                              data-fancybox
                            >
                              <i>
                                <FiPlay />
                              </i>
                            </button>
                          </div>
                        ) : null
                      }
                    />
                  ))
                ) : (
                  <p>No blogs available.</p>
                )}

                {/* Pagination controls */}
                <div className="pagination-container">
                  <Pagination
                    total={numberOfBlogs}
                    limit={itemsPerPage}
                    activePage={currentPage}
                    onChangePage={handlePageChange}
                    next={true}
                    prev={true}
                    boundaryLinks={true}
                    layout={["pager"]}
                    first={true}
                    last={true}
                    size="md"
                    maxButtons={10}

                  />
                </div>

                {/* Optional basic pagination */}
                <div className="basic-pagination" style={{ display: 'none' }}>
                  <ul>
                    <li>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i>
                          <CgArrowLongLeft />
                        </i>
                        <i>
                          <CgArrowLongLeft />
                        </i>
                      </button>
                    </li>

                    {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((pageNumber) => (
                      <li key={pageNumber} className={pageNumber === currentPage ? "active" : ""}>
                        <button onClick={() => handlePageChange(pageNumber)}>
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(numberOfBlogs / itemsPerPage)}
                      >
                        <i>
                          <CgArrowLongRight />
                        </i>
                        <i>
                          <CgArrowLongRight />
                        </i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <BlogLeftSideBar />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsArea;
