import React, { useEffect, useState } from "react";
import SingleTeam from "../../../components/SingleTeam/SingleTeam";
import TeamLeftBar from "./TeamLeftBar";
import axios from "axios";
import apiService from "../../../global/common/environment";
import Pagination from 'rsuite/Pagination';

const TeamArea = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [programs, setPrograms] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  // Fetching the program data from the API
  const getAllPrograms = async () => {
    const res = await axios.get(apiService.getProgramCourse);
    setPrograms(res.data.data);
  };

  const getAllProgramTypes = async () => {
    const res = await axios.get(apiService.getPrograms);
    setProgramTypes(res.data.map((val) => val));
  };

  useEffect(() => {
    getAllProgramTypes();
    getAllPrograms();
  }, []);

  // Handling checkbox change
  const handleProgramCheckboxChange = (value) => {
    setSelectedProgramIds((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  // Filter logic for programs based on search, title, and location
  const filteredPrograms = programs.filter((program) => {
    const searchMatch =
      program.courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      program.collageName.toLowerCase().includes(searchTerm.toLowerCase());

    const titleMatch =
      selectedTitles.length === 0 || selectedTitles.includes(program.collageName);

    const locationMatch =
      selectedLocations.length === 0 || selectedLocations.includes(program.location);

    const programIdMatch =
      selectedProgramIds.length === 0 || selectedProgramIds.includes(program.programId);

    return searchMatch && titleMatch && locationMatch && programIdMatch;
  });

  // Sorting the filtered programs
  const sortedPrograms = filteredPrograms.sort((a, b) => {
    if (sortOption === "name") return a.courseName.localeCompare(b.courseName);
    if (sortOption === "title") return a.collageName.localeCompare(b.collageName);
    return 0;
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredPrograms.length / itemsPerPage);
  const currentPrograms = sortedPrograms.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      window.scrollTo(0, 500);
    }
  };

  return (
    <section className="team__area pt-115 pb-110">
      <div className="">
        <div className="row">
          <div className="intro-text">
            <h1>Browse Our Programs</h1>
            {/* <p>
              Let us help you find what you're looking for! To best optimize
              your search results:
            </p> */}
            <ul>
              <li>
              Let us help you find what you're looking for! To best optimize
              your search results:
              </li>
              <li>
                Utilize the categories on the left-hand side to narrow down your
                search based on specific criteria.
              </li>
              <li>
                When using the open text field, be sure to select 'Relevancy'
                from the 'Sort by' drop-down menu.
              </li>
            </ul>
          </div>

          {/* Sidebar for filtering */}
          <div className="col-lg-2 col-md-3 mb-4">
            <div className="filter-sidebar">
              {/* Program Type Dropdown */}
              <div className="dropdown filter-dropdown">
                <h4>Program Types</h4>
                <div className="dropdown-content">
                  {programTypes.map((title) => (
                    <div key={title._id} className="form-check">
                      <input
                        type="checkbox"
                        id={`title-${title.programName + title._id}`}
                        className="form-check-input"
                        onChange={() => handleProgramCheckboxChange(title._id)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`title-${title.programName + title._id}`}
                      >
                        {title.programName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Location Dropdown */}
              {/* <div className="dropdown filter-dropdown">
                <h4>Locations</h4>
                <div className="dropdown-content">
                  {["New York", "London", "Paris", "Toronto", "Dubai"].map(
                    (location) => (
                      <div key={location} className="form-check">
                        <input
                          type="checkbox"
                          id={`location-${location.replace(/\s+/g, "-").toLowerCase()}`}
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`location-${location.replace(/\s+/g, "-").toLowerCase()}`}
                        >
                          {location}
                        </label>
                      </div>
                    )
                  )}
                </div>
              </div> */}
            </div>
          </div>

          {/* Main Content Area */}
          <div className="col-lg-7 col-md-6">
            {/* Search and Sort */}
            <div className="row align-items-center mb-40">
              <div className="col-md-8 position-relative">
                {/* Search Input */}
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search programs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ paddingRight: "40px" }} // Add space for the icon
                />
              </div>
              <div className="col-md-4">
                {/* Sort Dropdown */}
                <select
                  className="form-control sort-input"
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                  style={{ fontSize: "14px", padding: "8px" }}
                >
                  <option value="">Sort by</option>
                  <option value="name">Name</option>
                  <option value="title">Title</option>
                </select>
              </div>
            </div>

            {/* Program Cards */}
            <div className="row g-4">
              {currentPrograms.length > 0 ? (
                currentPrograms.map((program, index) => (
                  <SingleTeam
                    key={program._id} // Use program._id as the key
                    name={program.courseName}
                    // title={program.collageName}
                    location={program.location}
                    id={program._id}
                  />
                ))
              ) : (
                <p>No programs found.</p>
              )}
            </div>
            <div className="pagination-container">
              <Pagination
                total={filteredPrograms.length}
                limit={itemsPerPage}
                activePage={currentPage}
                onChangePage={handlePageChange}
                next={true}
                prev={true}
                boundaryLinks={true}
                layout={["pager"]}
                first={true}
                last={true}
                size="md"
                maxButtons={10}
              />
            </div>
          </div>

          {/* Left Sidebar */}
          <div className="col-lg-2 col-md-3">
            <TeamLeftBar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamArea;
