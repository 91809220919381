import React from 'react'
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoFooter from '../HomeTwo/HomeTwoFooter/HomeTwoFooter';
import HomeOneHeaderN from '../Home/HomeOneHeaderN/HomeOneHeaderN';
import AgentArea from './AgentArea';
import HomeHeroSlider from '../Home/HomeHeroArea/HomeHeroSlider';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';


const Agentdashboard = () => {
  return (
   
            <>
                <PageHelmet pageTitle="agentPage" />
    
                {/* <HomeOneHeader/> */}
                <HomeOneHeaderN/>
                <HomeHeroSlider/>
          
          
                <AgentArea/>
                <HomeAbout/> 
                <CommonCtaArea/> 
               
                <HomeTwoFooter />
            </>
        );
    };
 

export default Agentdashboard
