import React from 'react';
import './SucessModal.scss'
const SuccessModal = ({ show, handleClose }) => {
  return (
    <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header bg-success text-white">
          <h5 className="modal-title">
            <i className="fas fa-thumbs-up" style={{ marginRight: '10px' }}></i>
            Profile Updated Successfully
          </h5>
          <button type="button" className="close text-white" onClick={handleClose} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <p style={{ fontSize: '18px', marginBottom: '20px' }}>
            You have successfully updated your profile.
          </p>
          <p>To activate your account, contact us at:</p>
          <p><strong>Phone:</strong> +91-96581-96581</p>
          <p><strong>WhatsApp:</strong> +91-98759-01624, +91-98759-01625</p>
          <p><strong>Email:</strong> agents@makestudy.com, p.singh@makestudy.com</p>
        </div>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-success" onClick={handleClose}>
            <i className="fas fa-check-circle" style={{ marginRight: '5px' }}></i> Close
          </button>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default SuccessModal;
