import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import apiService from "../../global/common/environment";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.scss"; // Custom SCSS file for styling

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {}; // Access email from the state

  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility

  const handleResetPassword = async () => {
    if (!otp || !newPassword || !confirmPassword) {
      toast.error("Please fill all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(apiService.verifyOtpAndResetPassword, {
        email,
        otpCode: otp,
        password: newPassword,
      });
      const data = response.data;

      if (data) {
        toast.success("Password reset successful!");
        navigate("/login"); // Redirect to login page after successful password reset
      } else {
        toast.error(data.message || "Error resetting password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="forgot-password-area">
      <div
        className="px-4 py-5 px-md-5 text-center text-lg-start"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="container">
          <div className="row gx-lg-5 align-items-center">
            {/* Left side (Intro Text) */}
            <div className="col-lg-6 mb-5 mb-lg-0">
              <h1 className="my-5 display-4 fw-bold ls-tight">
                Secure Your Account <br />
                <span className="text-primary">Quick and Easy</span>
              </h1>
              <p style={{ color: "#6c757d" }}>
                Enter the OTP sent to your email and reset your password securely. 
                We're committed to keeping your account safe.
              </p>
            </div>

            {/* Right side (Form Section) */}
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="card shadow">
                <div className="card-body py-5 px-md-5">
                  <h2 className="card-title text-center mb-4">Reset Password</h2>
                  <p className="text-center">For {email}</p>

                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* OTP Input */}
                    <div className="form-group mb-4">
                      <label htmlFor="otp" className="form-label">
                        OTP
                      </label>
                      <input
                        type="text"
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="form-control"
                        placeholder="Enter OTP"
                        required
                      />
                    </div>

                    {/* New Password Input with visibility toggle */}
                    <div className="form-group mb-4 position-relative">
                      <label htmlFor="newPassword" className="form-label">
                        New Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="form-control"
                        placeholder="Enter New Password"
                        required
                      />
                      <span
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "🙈" : "👁️"}
                      </span>
                    </div>

                    {/* Confirm Password Input */}
                    <div className="form-group mb-4 position-relative">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control"
                        placeholder="Confirm New Password"
                        required
                      />
                      <span
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "🙈" : "👁️"}
                      </span>
                    </div>

                    {/* Password requirements */}
                    <div className="password-requirements mb-4">
                      <p className="mb-2">Minimum Password Requirements:</p>
                      <ul>
                        <li>At least 10 characters</li>
                        <li>One uppercase & one lowercase letter</li>
                        <li>One special character</li>
                      </ul>
                    </div>

                    {/* Reset Button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-4"
                        onClick={handleResetPassword}
                        disabled={loading}
                      >
                        {loading ? "Resetting Password..." : "Reset Password"}
                      </button>
                    </div>

                    {/* Back to login */}
                    <div className="text-center">
                      <p>
                        Remember your password? <a href="/login">Login</a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ForgotPassword;
