import React, { useState } from "react";
import axios from "axios";
import "./setpassword.scss"; // Import the SCSS file
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../global/common/environment";

const Setpasswordarea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email,otpCode } = location.state || {};
 
  console.log(location);

  const [formEmail, setFormEmail] = useState(email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      const dataFromCache=JSON.parse(localStorage.getItem("userCache"))
      const payload = {
        createUserDto: dataFromCache,
        password: password,
        // otpCode:otpCode
      };
      console.log(payload,"payload")

      setLoading(true);
      try {
        const response = await axios.post(
          apiService.setPassword,
          payload
        );

        if (response.data.message) {
          console.log(`Password for ${formEmail} has been updated!`);
          localStorage.removeItem("userCache");
          navigate("/");
        }
      } catch (error) {
        console.error('Error updating password:', error);
        setError('Error updating password. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Passwords do not match.');
    }
  }
  return (
    <section className="set-password-area">
      <div
        className="px-4 py-5 px-md-5 text-center text-lg-start"
        style={{ backgroundColor: "hsl(0, 0%, 96%)" }}
      >
        <div className="container">
          <div className="row gx-lg-5 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <h1 className="my-5 display-3 fw-bold ls-tight">
                Empowering Agents, <br />
                <span className="text-primary">Transforming futures.</span>
              </h1>
              <p style={{ color: "hsl(217, 10%, 50.8%)" }}>
                Partner with MakeStudy and watch your business thrive. By
                signing up, you'll gain access to a wealth of benefits and
                resources tailored to support your growth. By connecting
                institutions with our trusted network of partner agents, we
                create opportunities for growth, innovation, and success.
                Together, we open doors to endless possibilities, helping you
                achieve your highest potential
              </p>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="card">
                <div className="card-body py-5 px-md-5">
                  <h2 className="card-title text-center mb-4">
                    Create Password
                  </h2>{" "}
                  {/* Added heading */}
                  <form onSubmit={handleSubmit}>
                    {/* Password input with visibility toggle */}
                    <div className="form-outline mb-4 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        className="form-control password-input"
                        required
                      />
                      <label className="form-label">New Password</label>
                      <span
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "🙈" : "👁️"}
                      </span>
                    </div>
                    {/* Confirm Password input */}
                    <div className="form-outline mb-4 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="form-control password-input"
                        required
                      />
                      <label className="form-label">Confirm Password</label>
                      <span
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "🙈" : "👁️"}
                      </span>
                    </div>
                    {/* Password requirements */}
                    <div className="password-requirements mb-4">
                      <p>Minimum Password Requirements:</p>
                      <ul>
                        <li>
                          One symbol, at least 10 characters, lowercase letter,
                          uppercase letter
                        </li>
                      </ul>
                    </div>
                    {/* Error message */}
                    {error && (
                      <p className="error-message text-center">{error}</p>
                    )}{" "}
                    {/* Centered error message */}
                    {/* Submit button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn z-btn btn-block mb-4"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Update Password"}
                      </button>
                    </div>
                    {/* Additional links */}
                    <div className="text-center">
                      <p>
                        Already have an account? <a href="/login">Login</a>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          id="termsCheckbox"
                          name="terms"
                        />
                        I have read and agree to the{" "}
                        <a href="link-to-terms">Terms and Conditions</a> and the{" "}
                        <a href="link-to-privacy">Privacy and Cookies Policy</a>
                        *.
                      </p>
                      <h1>
                        Register as a{" "}
                        <a href="/recruitment-partner">Recruitment Partner</a>
                      </h1>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Setpasswordarea;
