import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './BlogSingleItem.scss'

const truncateText = (text, maxLength) => {
   if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
   }
   return text;
};

const BlogSingleItem = ({ image, user_image, name, title, description, video_icon, id }) => {
   const truncatedDescription = truncateText(description, 500); // Adjust maxLength as needed

   return (
      <div className="blog__item set">
         <div className='img-hover-zoom--blur'>
         <img src={image} alt={title} className='blog-feature-image'/>
         </div>
         <div className="blog__content">
            <div className="blog__info">
               {/* <img src={user_image} alt={'blog image'} /> */}
               <h5>{name}</h5>
            </div>
            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{ __html: truncatedDescription }}></p>
            <Link to={`/blogDetails/${id}`} className="read-more">Read More</Link>
            {video_icon}
         </div>
      </div>
   );
};

// PropTypes for type checking
BlogSingleItem.propTypes = {
   image: PropTypes.string,
   user_image: PropTypes.string,
   name: PropTypes.string,
   title: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
   video_icon: PropTypes.node
};

export default BlogSingleItem;
