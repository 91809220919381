import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Table, Offcanvas } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./ProgramTypes.scss";
import apiService from "../../../../global/common/environment";
import { MdDelete, MdEdit } from "react-icons/md";
import Swal from "sweetalert2"; // Import SweetAlert
// Define toolbar options for Quill editor
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];
const ProgramSchemaForm = () => {
  const [formData, setFormData] = useState({
    programName: "",
    programCode: "",
    programDescription: "",
    programImage: "",
    viewOn: "",
  });
  const [programs, setPrograms] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [editingProgramId, setEditingProgramId] = useState(null);
  const [currentField, setCurrentField] = useState("programDescription");
  const fileInputRef = useRef(null);

  // Fetch programs
  const getPrograms = async () => {
    const response = await axios.get(apiService.getPrograms);
    setPrograms(response.data);
  };

  const getProgram = async (id) => {
    const response = await axios.get(apiService.getProgram(id));
    setFormData(response.data);
  };

  const deleteProgram = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this program!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(apiService.deleteProgram(id));
          getPrograms(); // Refresh the program list after deletion
          Swal.fire("Deleted!", "Your program has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting program:", error);
          Swal.fire(
            "Error!",
            "Failed to delete the program. Please try again.",
            "error"
          );
        }
      } else {
        Swal.fire("Cancelled", "Your program is safe!", "info");
      }
    });
  };

  const editProgram = async () => {
    formData.viewOn = [formData.viewOn];
    try {
      await axios.put(apiService.updateProgram(editingProgramId), formData);
      resetForm();
      toggleSidebar();
      getPrograms(); // Refresh the program list
    } catch (error) {
      console.error("Error updating program:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingProgramId) {
      await editProgram(); // Edit existing program
    } else {
      await addProgram(); // Add new program
    }
  };

  const addProgram = async () => {
    formData.viewOn = [formData.viewOn];
    try {
      await axios.post(apiService.createProgram, formData);
      resetForm();
      getPrograms(); // Refresh the programs list after adding a new program
      toggleSidebar(); // Close sidebar after submission
    } catch (error) {
      console.error(error);
    }
  };

  const resetForm = () => {
    setFormData({
      programName: "",
      programCode: "",
      programDescription: "",
      programImage: "",
      viewOn: "",
    });
    setEditingProgramId(null); // Reset editing ID
    fileInputRef.current.value = ""; // Reset the file input
  };

  const openEditModal = (program) => {
    setFormData({
      programName: program.programName,
      programCode: program.programCode,
      programDescription: program.programDescription,
      programImage: program.programImage,
      viewOn: program.viewOn || "", // Set viewOn value if available
    });
    setEditingProgramId(program._id); // Set the ID of the program being edited
    toggleSidebar(); // Open the sidebar
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, programDescription: value });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, programImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const openDescriptionModal = () => setIsDescriptionModalOpen(true);
  const closeDescriptionModal = () => setIsDescriptionModalOpen(false);

  // Save modal content (description)
  const saveModalContent = () => {
    setFormData({
      ...formData,
      programDescription: formData.programDescription,
    });
    setIsDescriptionModalOpen(false); // Close the modal after saving
  };

  useEffect(() => {
    getPrograms();
  }, []);

  // Open/close sidebar
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="program-schema-form-container mt-5">
      <div className="d-flex justify-content-between">
        <h1>Program Categories</h1>
        <Button variant="primary" onClick={toggleSidebar}>
          Add Category
        </Button>
      </div>

      <Offcanvas
        show={isSidebarOpen}
        onHide={toggleSidebar}
        placement="end"
        className="offcanvas"
      >
        <Offcanvas.Header closeButton className="offcanvas-header">
          <Offcanvas.Title className="offcanvas-title">
            {editingProgramId ? "Edit Program" : "Add Program"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="programName" className="form-label">
                Program Name
              </label>
              <input
                type="text"
                className="form-control"
                id="programName"
                name="programName"
                value={formData.programName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="programCode" className="form-label">
                Program Code
              </label>
              <input
                type="text"
                className="form-control"
                id="programCode"
                name="programCode"
                value={formData.programCode}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <Button variant="secondary" onClick={openDescriptionModal}>
                {editingProgramId ? "Edit Description" : "Add Description"}
              </Button>
            </div>
            <div className="mb-3">
              <label htmlFor="programImage" className="form-label">
                Upload Program Image
              </label>
              {formData.programImage && (
                <img
                  src={formData.programImage || null}
                  alt="Program Image"
                  className="program-image"
                />
              )}
              <input
                type="file"
                className="form-control"
                id="programImage"
                name="programImage"
                onChange={handleImageChange}
                ref={fileInputRef}
                required={!editingProgramId} // Required for new programs only
              />
            </div>
            <br />
            {/* <label htmlFor="viewOn">View On</label>
            <br />
            <select
              name="viewOn"
              id="viewOn"
              value={formData.viewOn}
              onChange={handleChange}
              style={{ scale: 1.5 }}
            >
              <option value="" disabled>
                select
              </option>
              <option value="Hide">Hide</option>
              <option value="Show">Show</option>
            </select> */}
            <br />
            <Button type="submit" className="z-btn mt-3">
              {editingProgramId ? "Edit Program" : "Add Program"}
            </Button>
          </form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={isDescriptionModalOpen}
        onHide={closeDescriptionModal}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Program Description</Modal.Title>
        </Modal.Header>
      
          <ReactQuill
            className="custom-react-quill"
            value={formData.programDescription}
            onChange={handleDescriptionChange}
           modules={{ toolbar: toolbarOptions }}
          theme="snow"
          
           
          />
        
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDescriptionModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => saveModalContent(formData.programDescription)} // Save the description and close modal
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Table for displaying programs */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Program Name</th>
            <th>Program Code</th>
            <th>Program Description</th>
            <th>Program Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {programs.map((program) => (
            <tr key={program._id}>
              <td>{program.programName}</td>
              <td>{program.programCode}</td>
              <td
                dangerouslySetInnerHTML={{ __html: program.programDescription }}
              ></td>
              <td>
                <img
                  src={program.programImage}
                  alt="Program Image"
                  style={{ width: "100px" }}
                />
              </td>
              <td>
                <MdEdit
                  className="edit-icon"
                  onClick={() => openEditModal(program)}
                />
                <MdDelete
                  className="delete-icon"
                  onClick={() => deleteProgram(program._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProgramSchemaForm;
