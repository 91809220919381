// import React, { useEffect, useState } from "react";
// import "./live.currency.scss";
// import axios from "axios";
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// // Register Chart.js components
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

// const LiveCurrency = () => {
//   const [chartData, setChartData] = useState({});

//   const currencyApiKey = "dcdff190241a1b4e936886070050dd12";

//   useEffect(() => {
//     const fetchCurrencyData = async () => {
//       try {
//         const response = await axios.get(`https://api.exchangeratesapi.io/v1/latest?access_key=${currencyApiKey}`);
//         const data = response.data.rates;

//         // Prepare data for the horizontal bar chart
//         const labels = Object.keys(data).slice(0, 10);
//         const values = Object.values(data).slice(0, 10);

//         // Generate a different color for each bar
//         const backgroundColors = labels.map(() => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`);
//         const borderColors = labels.map(() => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`);

//         setChartData({
//           labels: labels,
//           datasets: [
//             {
//               label: 'Currency Exchange Rates',
//               data: values,
//               backgroundColor: backgroundColors,
//               borderColor: borderColors,
//               borderWidth: 1,
//             }
//           ],
//         });
//       } catch (error) {
//         console.error("Error fetching currency data", error);
//       }
//     };

//     fetchCurrencyData();
//   }, [currencyApiKey]);

//   return (
//     <div className="live-currency">
//       <h2>Currency Exchange Rates</h2>
//       {chartData.labels ? (
//         <Bar
//           data={chartData}
//           options={{
//             indexAxis: 'y', // Change to horizontal bar chart
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               tooltip: {
//                 callbacks: {
//                   label: function(context) {
//                     let label = context.dataset.label || '';
//                     if (label) {
//                       label += ': ';
//                     }
//                     if (context.parsed.x !== null) {
//                       label += new Intl.NumberFormat().format(context.parsed.x);
//                     }
//                     return label;
//                   }
//                 }
//               }
//             },
//             scales: {
//               x: {
//                 title: {
//                   display: true,
//                   text: 'Rate'
//                 },
//                 beginAtZero: true
//               },
//               y: {
//                 title: {
//                   display: true,
//                   text: 'Currency'
//                 },
//                 ticks: {
//                   autoSkip: false // Prevents skipping of labels
//                 }
//               }
//             }
//           }}
//         />
//       ) : (
//         <p>Loading chart...</p>
//       )}
//     </div>
//   );
// };

// export default LiveCurrency;


// import React, { useEffect, useState } from "react";
// import "./live.currency.scss";
// import axios from "axios";
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// // Register Chart.js components
// ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

// const LiveCurrency = () => {
//   const [chartData, setChartData] = useState({});

//   const currencyApiKey = "dcdff190241a1b4e936886070050dd12";

//   useEffect(() => {
//     const fetchCurrencyData = async () => {
//       try {
//         const response = await axios.get(`https://api.exchangeratesapi.io/v1/latest?access_key=${currencyApiKey}`);
//         const data = response.data.rates;

//         // Prepare data for the line chart
//         const labels = Object.keys(data).slice(0, 10);
//         const values = Object.values(data).slice(0, 10);

//         // Generate a different color for the line
//         const borderColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`;

//         setChartData({
//           labels: labels,
//           datasets: [
//             {
//               label: 'Currency Exchange Rates',
//               data: values,
//               borderColor: borderColor,
//               backgroundColor: 'rgba(0, 0, 0, 0)',
//               borderWidth: 2,
//               fill: false, // Disable filling under the line
//             }
//           ],
//         });
//       } catch (error) {
//         console.error("Error fetching currency data", error);
//       }
//     };

//     fetchCurrencyData();
//   }, [currencyApiKey]);

//   return (
//     <div className="live-currency">
//       <h2>Currency Exchange Rates</h2>
//       {chartData.labels ? (
//         <Line
//           data={chartData}
//           options={{
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               tooltip: {
//                 callbacks: {
//                   label: function(context) {
//                     let label = context.dataset.label || '';
//                     if (label) {
//                       label += ': ';
//                     }
//                     if (context.parsed.y !== null) {
//                       label += new Intl.NumberFormat().format(context.parsed.y);
//                     }
//                     return label;
//                   }
//                 }
//               }
//             },
//             scales: {
//               x: {
//                 title: {
//                   display: true,
//                   text: 'Currency'
//                 },
//                 ticks: {
//                   autoSkip: false // Prevents skipping of labels
//                 }
//               },
//               y: {
//                 title: {
//                   display: true,
//                   text: 'Rate'
//                 },
//                 beginAtZero: true
//               }
//             }
//           }}
//         />
//       ) : (
//         <p>Loading chart...</p>
//       )}
//     </div>
//   );
// };

// export default LiveCurrency;

import React, { useEffect, useState } from "react";
import "./live.currency.scss";
import axios from "axios";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

const LiveCurrency = () => {
  const [chartData, setChartData] = useState({});

  const currencyApiKey = "dcdff190241a1b4e936886070050dd12";

  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        const response = await axios.get(`https://api.exchangeratesapi.io/v1/latest?access_key=${currencyApiKey}`);
        const data = response.data.rates;

        // Prepare data for the line chart
        const labels = Object.keys(data).slice(0, 10);
        const values = Object.values(data).slice(0, 10);

        // Generate a different color for the line
        const borderColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`;

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Currency Exchange Rates',
              data: values,
              borderColor: borderColor,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderWidth: 2,
              fill: false, // Disable filling under the line
            }
          ],
        });
      } catch (error) {
        console.error("Error fetching currency data", error);
      }
    };

    fetchCurrencyData();
  }, [currencyApiKey]);

  return (
    <div className="live-currency">
      <h2>Currency Exchange Rates</h2>
      {chartData.labels ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat().format(context.parsed.y);
                    }
                    return label;
                  }
                }
              }
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Currency'
                },
                ticks: {
                  autoSkip: false // Prevents skipping of labels
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Rate'
                },
                beginAtZero: true
              }
            }
          }}
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default LiveCurrency;

