import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom"; // import useLocation
import Collapsible from "react-collapsible";
import { BiMap } from "react-icons/bi";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import SignupModal from "../Forms/SignupModal";
import LoginModal from "../Forms/LoginModal";

const Sidebar = ({ show, handleClose }) => {
  // State to manage modals
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  // Handlers for modals
  const handleOpenSignupModal = () => setOpenSignupModal(true);
  const handleCloseSignupModal = () => setOpenSignupModal(false);
  
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  // Get the current location
  const location = useLocation();

  // Check if the current page is the homepage
  const isHomePage = location.pathname === "/";

  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            <div className="sidelogo">
              <a href="index.html">
                <img
                  src="/assets/img/Webimg/logo1.webp"
                  alt="logo"
                  style={{ width: "150px", height: "auto" }}
                />
              </a>
            </div>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <section>
              <div className="p-0">
                <div className="sidebar__tab">
                  <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="menu-tab"
                        data-bs-toggle="tab"
                        href="#menu"
                        role="tab"
                        aria-controls="menu"
                        aria-selected="true"
                      >
                        menu
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="info-tab"
                        data-bs-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="false"
                      >
                        info
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="sidebar__content">
                  <div className="tab-content" id="sidebar-tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="menu"
                      role="tabpanel"
                      aria-labelledby="menu-tab"
                    >
                      <div className="side_navBar">
                        {/* Existing Nav Links */}
                        <Collapsible
                          trigger={<NavLink to="/home">Home</NavLink>}
                          triggerTagName="div"
                          triggerOpenedClassName="icon_close"
                          triggerClassName="iconAdd"
                          open={false}
                        >
                          {/* <ul className="sidebar_sub_menu text-white">
                            <li className="pt-10">
                              <NavLink to="/home">Home style 1</NavLink>
                            </li>
                            <li>
                              <NavLink to="/homeTwo">Home style 2</NavLink>
                            </li>
                          </ul> */}
                        </Collapsible>

                        <div className="about iconAdd">
                          <NavLink to="/about">About Us </NavLink>
                        </div>

                        <Collapsible
                          trigger={<NavLink to="/courses">Courses</NavLink>}
                          triggerTagName="div"
                          triggerOpenedClassName="icon_close"
                          triggerClassName="iconAdd"
                          open={false}
                        >
                          {/* <ul className="sidebar_sub_menu submenu text-white">
                            <li>
                              <NavLink to="/courses">Courses</NavLink>
                            </li>
                            <li>
                              <NavLink to="/servicesDetails">Course Details</NavLink>
                            </li>
                          </ul> */}
                        </Collapsible>

                        <Collapsible
                          trigger={<NavLink to="/blogs">Blogs</NavLink>}
                          triggerTagName="div"
                          triggerOpenedClassName="icon_close"
                          triggerClassName="iconAdd"
                          open={false}
                        >
                          {/* <ul className="sidebar_sub_menu submenu text-white">
                            <li>
                              <NavLink to="/blogs">Blogs</NavLink>
                            </li>
                            <li>
                              <NavLink to="/blogDetails">Blog Details</NavLink>
                            </li>
                          </ul> */}
                        </Collapsible>

                        <div className="about iconAdd border-0">
                          <NavLink to="/contact">Contact Us</NavLink>
                        </div>

                        {/* Show Signup and Login buttons only on the homepage */}
                        {isHomePage && (
                          <>
                            <div className="about iconAdd border-0">
                              <button className="z-btn" onClick={handleOpenSignupModal}>
                                Signup
                              </button>
                            </div>

                            <div className="about iconAdd border-0">
                              <button className="z-btn" onClick={handleOpenLoginModal}>
                                Login
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="info"
                      role="tabpanel"
                      aria-labelledby="info-tab"
                    >
                      <div className="sidebar__info">
                        {/* Info Content */}
                        <p>
                          MakeStudy (Deepak Enterprises) with 20+ years of
                          industrial experience in study abroad consulting. “At
                          MakeStudy, we are committed to driving innovation in
                          student recruitment. Our cutting-edge technology and
                          on-ground support services are designed to help you
                          reach your recruitment goals effectively and
                          efficiently.
                        </p>
                        <a href="contact.html" className="z-btn z-btn-white">
                          Contact Us
                        </a>

                        <div className="sidebar__contact mt-30">
                          <ul>
                            <li>
                              <div className="icon">
                                <i><BiMap /></i>
                              </div>
                              <div className="text">
                                <span>
                                  SCO-264-A, Pacca Bagh, Opp Old Courts, Jalandhar 144001, Punjab, India.
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i><FaEnvelope /></i>
                              </div>
                              <div className="text">
                                <span>
                                  <a href="mailto:agents@makestudy.com">
                                    agents@makestudy.com
                                  </a>
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i><FaPhoneAlt /></i>
                              </div>
                              <div className="text">
                                <span>
                                  <a href="tel:+91 96581-96581, +91 98880-98881">
                                    +91 96581-96581, +91 98880-98881
                                  </a>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {/* Signup Modal */}
      <SignupModal open={openSignupModal} onClose={handleCloseSignupModal} />

      {/* Login Modal */}
      <LoginModal open={openLoginModal} onClose={handleCloseLoginModal} />
    </>
  );
};

export default Sidebar;
