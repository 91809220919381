import React, { useEffect, useState } from "react";
import axios from "axios";
import HomeTwoSingleBlog from "../../../components/HomeTwoSingleBlog/HomeTwoSingleBlog";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import { forwardRef } from 'react';
import apiService from "../../../global/common/environment";

const HomeTwoBlogs = forwardRef(( props,ref)  => {
  const [blogs, setBlogs] = useState([]);

  // Fetch blog data
  useEffect(() => {
    axios.get(apiService.getBlogs(3,1))
      .then((response) => {
        if (response.data && response.data.data) {
          setBlogs(response.data.data);
          console.log(response)
        }
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
      });
  }, []);

  return (
    <>
      <section className="blog__area grey-bg-15 pt-115 pb-135 fade-up" ref={ref}>
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <div className="section__title section__title-3 mb-65 fade-up">
                <span>What's new at Makestudy</span>
                <h2>Latest Blog and news.</h2>
              </div>
            </div>
          </div>
          <div className="row fade-up">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <HomeTwoSingleBlog
                  key={blog.id}
                  date={new Date(blog.publish_date).toLocaleDateString("en-GB")}
                  name={blog.author}
                  desc={blog.title}
                  id={blog._id}
                />
              ))
            ) : (
              <p>Loading blogs...</p>
            )}
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="blog__more mt-60">
                <Link to="/blogs" className="z-btn z-btn-border">
                  View all
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default HomeTwoBlogs;
