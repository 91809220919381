import React from 'react';
import { Link } from 'react-router-dom';
import  { forwardRef } from 'react';

const HomeThreeHeroSection = forwardRef(( props,ref) => {
   return (
      <>
         <section className="hero__area p-relative" ref={ref} >
            <div className="hero__shape">
               <img className="one" src="/assets/img/icon/slider/03/icon-1.png" alt="" />
               <img className="two" src="/assets/img/icon/slider/03/icon-2.png" alt="" />
               <img className="three" src="/assets/img/icon/slider/03/icon-3.png" alt="" />
               <img className="four" src="/assets/img/icon/slider/03/icon-4.png" alt="" />
               <img className="five" src="/assets/img/icon/slider/03/icon-6.png" alt="" />
               <img className="six" src="/assets/img/icon/slider/03/icon-7.png" alt="" />
            </div>
            <div className="hero__item hero__height d-flex align-items-center fade-up">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6 col-lg-5 order-last">
                        <div className="hero__thumb-wrapper ml-100 scene p-relative">
                           <div className="hero__thumb one">
                              <img className="layer" data-depth="0.2" src="/assets/img/slider/03/slider-1.jpg" alt="" />
                           </div>
                           <div className="hero__thumb two ">
                              <img className="layer" data-depth="0.3" src="/assets/img/slider/03/slider-2.jpg" alt="" />
                           </div>
                           <div className="hero__thumb three ">
                              <img className="layer" data-depth="0.4" src="/assets/img/slider/03/slider-3.jpg" alt="" />
                           </div>
                           <div className="hero__thumb four">
                              <img className="layer" data-depth="0.5" src="/assets/img/slider/03/slider-4.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-7 d-flex align-items-center fade-up">
                        <div className="hero__content fadeup">
                           <span>Shaping Education | Shaping Future</span>
                           <h1 className='fadeup'>Ready to make an impact?</h1>
                           <p>At MakeStudy, we are more than just a networking company—we are catalysts for success. By partnering with us, you gain access to exclusive deals from top educational institutions across the globe. We empower our agents with unmatched opportunities, resources, and support to expand their reach and drive their business forward. <br/>Join us today and be part of a network that’s ready to make a difference. Let’s elevate your consultancy to new heights together.</p>
                           <Link to="/" className="z-btn ">What we do</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
});

export default HomeThreeHeroSection;