import React from 'react';
import Slider from 'react-slick';
import './Certificate.scss'; // Import the SCSS file
import IASBadge from '../../pages/HomeTwo/HomeTwoAchievement/IASBadge';

const Certificate = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 5, // Adjust number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true, // For better visual alignment
    responsive: [
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium screens (tablets)
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Small screens (mobile)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="certificate-container">
      <h1 className="certificate-heading">Other Certificates</h1>
      <br/>
      <Slider {...settings}>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/certifcatepdf1.png" alt="Slide 1" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/certificatepdf2.png" alt="Slide 2" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Norquest.jpeg" alt="Slide 5" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/OTU.jpeg" alt="Slide 6" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Queens.jpeg" alt="Slide 7" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/vanier.jpeg" alt="Slide 8" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Adler.jpeg" alt="Slide 9" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/UNF.jpeg" alt="Slide 10" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/URegina.jpeg" alt="Slide 11" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Alexander college.jpeg" alt="Slide 12" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Calgary.jpeg" alt="Slide 13" />
        </div>
        <div className="slide-item">
          <img src="assets/img/Webimg/certifications/Olds.jpeg" alt="Slide 14" />
        </div>
      
      </Slider>
    </div>
  );
};

export default Certificate;
