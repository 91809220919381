

// export default HomeThreeServices;
import React from "react";
import HomeThreeSingleService from "../../../components/HomeThreeSingleService/HomeThreeSingleService";
import { forwardRef } from 'react';
const HomeThreeServices = () => {
  return (
    <section
      className="services__area-2 mt-120  p-relative" 
      // style={{
      //    position: 'relative',
      //    overflow: 'hidden',
      //    background: `url(assets/img/bg/wave-bg-2.png) no-repeat center bottom`,
      //    backgroundSize: 'cover',

      // }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <div className="section__title section__title-3 mb-85 text-center fade-up">
              <span>Our Services</span>
              <h2>Discover Our Services</h2>
            </div>
          </div>
        </div>

        <div className="row g-0" >
          <HomeThreeSingleService
            image="3"
            title="Networking and Building Strategic Partnerships"
            subtitle="In our business, networking and cultivating strategic partnerships are crucial for sustained growth and success. We pride ourselves on having access to an extensive and well-established network of trusted agents and collaborators. This network not only amplifies our reach but also enhances our ability to offer tailored solutions to our clients. By continuously nurturing these relationships, we ensure that our business remains at the forefront of industry trends and opportunities, allowing us to deliver exceptional value to all stakeholders involved.."
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "16px",
              minHeight: "300px", // Adjust as needed for uniform height
              backgroundColor: "#f8f9fa", // Optional background
              border: "1px solid #ddd", // Optional border
            }}
          />
          <HomeThreeSingleService
            image="2"
            title="School Partnerships: Expanding Global Reach"
            subtitle="Our school partnerships are designed to expand our global reach and create new opportunities for our clients. By collaborating with educational institutions worldwide, we can offer a diverse range of options to students and help them find the best fit for their academic and career goals. These partnerships enhance our ability to provide comprehensive and customized solutions."
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "16px",
              minHeight: "300px", // Adjust as needed for uniform height
              backgroundColor: "#f8f9fa", // Optional background
              border: "1px solid #ddd", // Optional border
            }}
          />
          <HomeThreeSingleService
            image="1"
            title="Student Recruitment: Simplifying Admissions with Cutting-Edge Technology"
            subtitle="We leverage cutting-edge technology to simplify the student recruitment process and streamline admissions. Our advanced tools and systems make it easier for students to apply, and for institutions to manage applications efficiently. Our technology-driven approach ensures a smooth and effective recruitment experience for all parties involved"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "16px",
              minHeight: "300px", // Adjust as needed for uniform height
              backgroundColor: "#f8f9fa", // Optional background
              border: "1px solid #ddd", // Optional border
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeThreeServices;
