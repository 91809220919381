import ScrollTop from "./components/ScrollTop";
import { useState, useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom"; // Import the 'useNavigation' function
import Loading from "./components/shared/Loading/Loading";
import { ToastContainer } from "react-toastify";
function App() {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (navigation.state === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [navigation.state]);
  return (
    <>
      <ScrollTop />
      <ToastContainer autoClose={800}  />

      <main>{isLoading ? <Loading /> : <Outlet />}</main>
    </>
  );
}

export default App;
