import React, { useEffect, useState } from "react";
import SinglePortfolio from "../../../components/SinglePortfolio/SinglePortfolio";
import data from "../../../data/portfolioData";
import TeamLeftBar from "../../Team/TeamArea/TeamLeftBar";
import apiService from "../../../global/common/environment";
import axios from "axios";

const PortfolioArea = () => {
const [programs,setPrograms]=useState([]);
// debugger
  // Prepare images for Lightbox
  const getPrograms=async()=>{
    const response = await axios.get(apiService.getPrograms);
    console.log(response.data);
    setPrograms(response.data);
  }
  const images = programs.map((portfolio) => portfolio.programImage);

useEffect(()=>{
  getPrograms()
},[])
  return (
    <section className="portfolio__area pt-110 ">
      <div className="">
        <div className="row">
          {/* Main content area */}
          <div className="col-xl-9 col-md-12">
            <div className="portfolio__menu mb-40">
              <span className="portfolio__subtitle">
                Shaping Education | Shaping Future
              </span>
              <h1 className="portfolio__title">
                Explore Our Courses and Programs
              </h1>
              <p className="portfolio__description">
                At MakeStudy, we offer a range of specialized courses and
                programs designed to equip you with the knowledge and skills
                needed to excel in student recruitment and educational
                consultancy. Our offerings are tailored to help agents build
                successful careers and expand their reach globally.
                 Explore our portfolio to see the diverse opportunities
                available and how you can partner with us to enhance your
                services.
              </p>
            </div>
            <div className="row">
              {programs.map((programs, index) => (
                <SinglePortfolio
                  key={programs.id} // Use a unique key from the portfolio data
                  portfolio={programs}
                  index={index} // Pass the index for lightbox functionality
                  images={programs.programImage} // Pass all images for lightbox
                />
              ))}
            </div>
          </div>

            <TeamLeftBar className="team-left-bar" />
         
        </div>
      </div>
    </section>
  );
};

export default PortfolioArea;
