import React from "react";
import AdminSideNav from "./components/AdminSideNav/AdminSideNav";
import AdminNavbar from "./components/AdminNavBar/AdminNavBar";
import "./admin.home.scss";
import "boxicons/css/boxicons.min.css";
import ListItems from "./components/DashBoardComponents/ListItems/ListItems";
import "animate.css";
import LiveCurrency from "./components/DashBoardComponents/LiveCurrency/LiveCurrency";
import { ToastContainer } from "react-toastify";

const AdminHomePage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  return (
    <div>
      <div className="main-container">
        {/* welcome text */}
        <div className="" style={{ marginTop: "70px" }}>
          <h1
            className=" animate__animated animate__backInRight"
            style={{ fontSize: "60px", textAlign: "center" }}
          >
            Welcome to the admin dashboard !
          </h1>
        </div>
        <br/>
        <br/>
        {/* welcome text end */}
            <ListItems />
      </div>
    </div>
  );
};

export default AdminHomePage;
