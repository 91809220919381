import React, { useRef, useState } from "react";
import { Sidebar, Menu, Icon, Segment, Button } from "semantic-ui-react";
import "./admin.sidenav.scss"; // Import custom CSS for styling
import 'boxicons/css/boxicons.min.css';
import { Link, useNavigate } from "react-router-dom";
const AdminSideNav = ({ isOpen, onToggle }) => {
  // const [isOpen, setIsOpen] = useState(false);
    const toggleRef=useRef(false)
    let nav=useNavigate()

    // const toggleSidebar = (boolean) => {
    //   setIsOpen(boolean);
    // };
  
    return (
      // {/* <AdminSideNav /> */}

      <div className={`sidebar ${isOpen ? 'open' : ''}`} >
        <div className="logo-details">
          {/* <i className='bx bxl-c-plus-plus icon'></i> */}
          <div className="logo_name text-light"><span className="bg-danger">Make</span><span className="bg-primary">Study</span></div>
          <i className="bx bx-menu" id="btn" onClick={() => onToggle(!isOpen)}></i>
          {/* <i className="bx bx-menu" id="btn" onClick={() => toggleSidebar(!isOpen)}></i> */}

        </div>
        <ul className="nav-list">
          {/* <li>
            <i className='bx bx-search'></i>
            <input type="text" placeholder="Search..." />
            <span className="tooltip">Search</span>
          </li> */}
          {/* dashboard */}
          <li  onClick={()=>nav('/admin/admin-dashboard')}>
            <a >
              <i className='bx bx-grid-alt'></i>
              <span className="links_name"  >Dashboard</span>
            </a>
            <span className="tooltip">Dashboard</span>
          </li>
          {/* agents */}
          <li onClick={()=>nav('/admin/agentdisplay')}>
            <a>
              <i className='bx bx-user'></i>
              <span className="links_name">Agents</span>
            </a>
            <span className="tooltip">Agents</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-chat'></i>
              <span className="links_name">Messages</span>
            </a>
            <span className="tooltip">Messages</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-pie-chart-alt-2'></i>
              <span className="links_name">Analytics</span>
            </a>
            <span className="tooltip">Analytics</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-folder'></i>
              <span className="links_name">File Manager</span>
            </a>
            <span className="tooltip">Files</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-cart-alt'></i>
              <span className="links_name">Order</span>
            </a>
            <span className="tooltip">Order</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-heart'></i>
              <span className="links_name">Saved</span>
            </a>
            <span className="tooltip">Saved</span>
          </li>
          <li>
            <a href="#">
              <i className='bx bx-cog'></i>
              <span className="links_name">Setting</span>
            </a>
            <span className="tooltip">Setting</span>
          </li>
          {/* <li className="profile">
            <div className="profile-details">
              <img src="profile.jpg" alt="profileImg" />
              <div className="name_job">
                <div className="name">Prem Shahi</div>
                <div className="job">Web designer</div>
              </div>
            </div>
            <i className='bx bx-log-out' id="log_out"></i>
          </li> */}
        </ul>
      </div>
    );
  };
export default AdminSideNav;
