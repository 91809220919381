import React, { useEffect, useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineClose,
} from "react-icons/ai";
import Swal from "sweetalert2"; // Import SweetAlert2
import "./DisplayPrograms.scss";
import axios from "axios";
import apiService from "../../../../../global/common/environment";
import AddPrograms from "../AddPrograms/AddPrograms";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DisplayPrograms = () => {
  const [courses, setCourses] = useState([]);
  const [editCourseModal, setEditCourseModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const nav = useNavigate();

  const getAllPrograms = async () => {
    try {
      const res = await axios.get(apiService.getProgramCourse + "/admin");
      setCourses(res.data.data);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const handleView = (course) => {
    setSelectedCourse(course);
  };

  const handleClose = () => {
    setEditCourseModal(false);
    setSelectedCourse(null);
  };

  const handleEdit = (id) => {
    nav("/admin/programs/" + id);
  };

  const handleAddProgram = () => {
    nav("/admin/programs/");
  };

  const handleDelete = async (courseId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(apiService.deleteProgramCourse(courseId));
        getAllPrograms();
        toast.success("Course deleted successfully");
      } catch (err) {
        console.log(err);
        toast.error("Failed to delete course");
      }
    }
  };

  useEffect(() => {
    getAllPrograms();
  }, []);

  return (
    <>
      <div className="programs-header">
        <h1>Program Courses</h1>
        <button className="add-program-btn" onClick={handleAddProgram}>
          Add Program
        </button>
      </div>

      <div className="course-table">
        <table>
          <thead>
            <tr>
              <th>SNo</th>
              <th>Course Name</th>
              <th>Collage Name</th>
              <th>Description</th>
              <th>Duration</th>
              <th>Tuition Fee</th>
              <th>View</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course,i) => (
              <tr key={i}>
                <td>{i+1}</td>
                <td>{course.courseName}</td>
                <td>{course.collageName}</td>
                <td>{course.courseDescription}</td>
                <td>{course.courseLength}</td>
                <td>{course.annualTuitionFee}</td>
                <td>
                  <div>
                    {course.published === "Y" ? <span className="btn btn-success">Published </span>: <span className="btn btn-danger">Unpublished</span>}
                  </div>
                </td>
                <td className="">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div>
                      <button onClick={() => handleView(course)}>
                        <AiOutlineEye />
                      </button>
                    </div>
                    <div>
                      <button onClick={() => handleEdit(course._id)}>
                        <AiOutlineEdit />
                      </button>
                    </div>
                    <div>
                      <button onClick={() => handleDelete(course._id)}>
                        <AiOutlineDelete />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {selectedCourse && (
          <div className="modal">
            <div className="modal-content">
              <h2>{selectedCourse.courseName}</h2>
              <p>
                <strong>Program ID:</strong> {selectedCourse.programId}
              </p>
              <p>
                <strong>Collage Name:</strong> {selectedCourse.collageName}
              </p>
              <p>
                <strong>Description:</strong> {selectedCourse.courseDescription}
              </p>
              <p>
                <strong>Duration:</strong> {selectedCourse.courseLength}
              </p>
              <p>
                <strong>Tuition Fee:</strong> {selectedCourse.annualTuitionFee}
              </p>
              <p>
                <strong>Application Fee:</strong>{" "}
                {selectedCourse.applicationFee}
              </p>
              <p>
                <strong>LOA Deposit:</strong> {selectedCourse.LOAdeposit}
              </p>
              <p>
                <strong>Commission Amount:</strong>{" "}
                {selectedCourse.commissionAmount}{" "}
                {selectedCourse.commissionCurrency}
              </p>
              <p>
                <strong>Available Intakes:</strong>{" "}
                {selectedCourse.availableIntakes}
              </p>
              <p>
                <strong>Open Intakes:</strong> {selectedCourse.openIntakes}
              </p>
              <p>
                <strong>Course Details: </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.courseDetails,
                  }}
                />
              </p>
              <p>
                <strong>Course Requirements: </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.courseRequirements,
                  }}
                />
              </p>
              <p>
                <strong>Language Requirements: </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.languageRequirements,
                  }}
                />
              </p>
              <p>
                <strong>Career Opportunities: </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.careerOpportunities,
                  }}
                />
              </p>
              <p>
                <strong>Course Highlights: </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedCourse.courseHighlights,
                  }}
                />
              </p>
              <button className="close-btn" onClick={handleClose}>
                <AiOutlineClose />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DisplayPrograms;
