import React, { useEffect, useState } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import "./ServiceDetails.scss";
import axios from "axios";
import apiService from "../../../global/common/environment";
import { toast } from "react-toastify";

const ServicesDetailsArea = ({ id, setCourseName }) => {
  const [activeTab, setActiveTab] = useState("first");
  const [course, setCourse] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  // Fetch course details
  const getCourse = async () => {
    const res = await axios.get(apiService.getProgramCourseDetails(id));
    setCourse(res.data.data);
    setCourseName(res.data.data.courseName);
  };

  useEffect(() => {
    getCourse();
  }, []);

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiService.contactUs, {
        name: formData.name,
        email: formData.email,
      }, {
        // headers: {
        //   'Content-Type': 'application/json',
        //   'api-key': 'your-api-key',
        // },
      });

      if (response.status === 201) {
        toast.success("Thank you for contacting us!");
        setFormData({ name: '', email: '' });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send the message.');
    }
  };

  // Tab navigation handling
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <section className="services__details pt-115 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 order-last order-lg-first">
              <div className="services__sidebar mr-50">
                {/* Sidebar content */}
                <div
                  className="services__widget mb-40"
                  style={{ backgroundColor: "#124076 " }}
                >
                  <div className="services__widget-title">
                    <h2>Key Facts</h2>
                  </div>
                  <div className="services__widget-content">
                    <div className="services__link">
                      <ul>
                        <li><strong>Credentials:</strong><span>{course.credentials}</span></li>
                        <li><strong>Application Fees:</strong><span>{course.applicationFee}</span></li>
                        <li><strong>Open Intakes:</strong><span>{course.openIntakes}</span></li>
                        <li><strong>Duration:</strong><span>{course.duration} terms</span></li>
                        <li><strong>Tuition Fees:</strong><span>{course.annualTuitionFee}</span></li>
                        <li><strong>Seat confirmation Deposit:</strong><span> {course.LOAdeposit}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="services__action mb-15" style={{ backgroundColor: "#6B240C" }}>
                  <a href="/contact"><FiArrowRight /> Apply Now</a>
                </div>
                <div className="services__action mb-15" style={{ backgroundColor: "#E48F45" }}>
                  <a href="#"><AiOutlinePhone /> Talk to Advisor</a>
                </div>

                <div className="services__widget grey-bg-18 mb-40">
                  <div className="services__widget-title">
                    <h4>Contact Us</h4>
                  </div>
                  <div className="services__widget-content">
                    <div className="services__form">
                      <form onSubmit={handleSubmit}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <button type="submit" className="z-btn z-btn-3 w-100">
                          Contact Us
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8">
              <div className="services__text">
                <h3>{course.courseName} </h3>
                <p>{course.description}</p>
              </div>
              <div className="services__img mb-45 w-img">
                <img src={course.courseImage} alt={course.courseName} style={{height:'400px',backgroundSize:'contain'}} />
              </div>

              <h1>Program Details:</h1>

              {/* Tab navigation */}
              <div className="ui top attached tabular menu">
                <a
                  className={`item ${activeTab === "first" ? "active" : ""}`}
                  onClick={() => handleTabClick("first")}
                >
                  Program Requirements
                </a>
                <a
                  className={`item ${activeTab === "second" ? "active" : ""}`}
                  onClick={() => handleTabClick("second")}
                >
                  English Language Requirements
                </a>
                <a
                  className={`item ${activeTab === "third" ? "active" : ""}`}
                  onClick={() => handleTabClick("third")}
                >
                  Career Opportunities
                </a>
              </div>

              {/* Tab content */}
              <div className={`ui bottom attached tab segment ${activeTab === "first" ? "active" : ""}`}>
                <div dangerouslySetInnerHTML={{ __html: course.courseRequirements }} />
              </div>
              <div className={`ui bottom attached tab segment ${activeTab === "second" ? "active" : ""}`}>
                <div dangerouslySetInnerHTML={{ __html: course.languageRequirements }} />
              </div>
              <div className={`ui bottom attached tab segment ${activeTab === "third" ? "active" : ""}`}>
                <div dangerouslySetInnerHTML={{ __html: course.careerOpportunities }} />
              </div>

              <div className="services__list mb-40">
                <h1>Program Highlights:</h1>
                <div dangerouslySetInnerHTML={{ __html: course.courseHighlights }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesDetailsArea;
