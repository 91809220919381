import React, { useEffect, useState } from "react";
import "./AgentUpdate.scss";
import SuccessModal from "./SucessModal";
import { jwtDecode } from "jwt-decode";
import apiService from "../../global/common/environment";
import axios from "axios";

const AgentUpdate = () => {
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [agentBasicInfo, setAgentBasicInfo] = useState({});
  const [formValues, setFormValues] = useState({
    gender: "",
    agency: "",
    reg_no: "",
    country_code: "",
    alternativePhone: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    dob: "",
    agencyHeadOfficeAddress:"",
    noOfBranches:1,
    personName:"",  
    designation:"",
    email:"",
    countryPhoneCode:"",
    contactNo:""

  });
  const token = localStorage.getItem("userBasicInfo");
  const decoded = jwtDecode(token);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };
      console.log(newValues); // Log the updated values
      return newValues;
    });
  };
  
  const getBasicinfo = async () => {
    setAgentBasicInfo(decoded);
    return;
  };
  const step1 = async () => {
    const userData = await axios.put(apiService.editUser(decoded.id), {
      gender: formValues.gender,
      country_code: formValues.country_code,
      pincode:formValues.zipcode,
      phone_no: formValues.alternativePhone,
      dob: formValues.dob,
      pincode:formValues.zipcode,
    });
    const agentBasicInfo = await axios.post(apiService.createAgentInfo, {
      agentId: decoded.id,
      organizationName: formValues.agency,
      country: formValues.country,
      agencyHeadOfficeAddress:formValues.agencyHeadOfficeAddress,
      noOfBranches:formValues.noOfBranches,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      contactPersonInformation:{
        personName:formValues.personName,
        designation:formValues.designation,
        countryPhoneCode:formValues.countryPhoneCode,
        contactNo:formValues.contactNo,
        email:formValues.email
      }
    });
  };

  useEffect(() => {
    getBasicinfo();
  }, []);
  const nextStep = () => {
    // step1();

    setStep(step + 1);
    // if (step ===2) {
    // }
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  // Open modal on form success
  const handleSuccess = () => {
    step1()
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container-fluid agent-update-container">
      <div className="row justify-content-center">
        {/* Image Section */}
        <div className="col-12 col-lg-6 text-center">
          <div className="agent-image-container">
            <img
              src="assets/img/Webimg/WebBackground.jpg"
              alt="Agent"
              className="agent-image"
            />
          </div>
        </div>

        {/* Form Section */}
        <div className="col-12 col-lg-6 text-center p-0 mt-3 mb-2">
          <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
            <h2 id="heading"></h2>
            <div className="section__title section__title-3 mb-30">
              <span>Bulid Profile</span>
              <h2>Update Your Profile.</h2>
              <p>Fill all form fields to go to the next step</p>
            </div>
            <form id="msform">
              {/* Progress bar */}
              <ul id="progressbar">
                <li className={step >= 1 ? "active" : ""} id="account">
                  <strong>Account</strong>
                </li>
                <li className={step >= 2 ? "active" : ""} id="personal">
                  <strong>Address</strong>
                </li>
                {/* <li className={step >= 3 ? 'active' : ''} id="contact">
                  <strong>Contact Information</strong>
                </li> */}
                <li className={step >= 3 ? "active" : ""} id="personal">
                  <strong>contact</strong>
                </li>
                <li className={step >= 4 ? "active" : ""} id="confirm">
                  <strong>Finish</strong>
                </li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${(step - 1) * 33.33}%` }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <br />

              {/* Step 1: Account Information */}
              {step === 1 && (
                <fieldset>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="fs-title">Account Information:</h2>
                      </div>
                      <div className="col-5">
                        <h2 className="steps">Step 1 - 4</h2>
                      </div>
                    </div>
                    <label className="fieldlabels">First Name *</label>
                    <input
                      type="text"
                      name="fname"
                      placeholder="First Name"
                      value={agentBasicInfo?.first_name}
                      disabled={true}
                    />
                    <label className="fieldlabels">Last Name *</label>
                    <input
                      type="text"
                      name="lname"
                      placeholder="Last Name"
                      value={agentBasicInfo?.last_name}
                      disabled={true}
                    />
                    <label className="fieldlabels">DOB (Optional)</label>
                    <input
                      type="date"
                      name="dob"
                      placeholder="DOB"
                      onChange={handleFormChange}
                      value={formValues.dob}
                    />
                    <label className="fieldlabels">Gender</label>
                    <select
                      name="gender"
                      className="fieldlabels"
                      onChange={handleFormChange}
                      value={formValues.gender}
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>

                    <label className="fieldlabels">
                      Agency/Organization Name *
                    </label>
                    <input
                      type="text"
                      name="agency"
                      placeholder="Agency/Organization Name"
                      onChange={handleFormChange}
                      value={formValues.agency}
                    />
                    <label className="fieldlabels">
                      Registration/Licence No (Optional)
                    </label>
                    <input
                      type="text"
                      name="reg_no"
                      placeholder="Registration/Licence No"
                      onChange={handleFormChange}
                      value={formValues.reg_no}
                    />
                    <label className="fieldlabels">Email Address *</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={agentBasicInfo?.email}
                      disabled={true}
                    />
                    {/* <label className="fieldlabels">Country Phone Code *</label>
                    <input
                      type="text"
                      name="country_code"
                      placeholder="Country Phone Code"
                      onChange={handleFormChange}
                      value={formValues.country_code}
                    /> */}
                    <label className="fieldlabels">Phone No *</label>
                    <input
                      type="text"
                      name="phone_no"
                      placeholder="Phone No"
                      value={agentBasicInfo?.mobile_no}
                      disabled={true}
                    />
                    {/* <label className="fieldlabels">
                      Alternative Contact No (Optional)
                    </label>
                    <input
                      type="text"
                      name="alt_phone_no"
                      placeholder="Alternative Contact No"
                    /> */}
                     <label className="fieldlabels">Person Name *</label>
                    <input
                      type="text"
                      name="personName"
                      placeholder="Person Name"
                      onChange={handleFormChange}
                      value={formValues.personName}
                    />
                    <label className="fieldlabels">Designation *</label>
                    <input
                      type="text"
                      name="designation"
                      placeholder="Designation"
                      onChange={handleFormChange}
                      value={formValues.designation}
                    />
                  </div>
                  <input
                    type="button"
                    name="next"
                    className="next action-button"
                    value="Next"
                    onClick={nextStep}
                  />
                </fieldset>
              )}

              {/* Step 2: Address and Location */}
              {step === 2 && (
                <fieldset>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="fs-title">Agency/Head Office Address</h2>
                      </div>
                      <div className="col-5">
                        <h2 className="steps">Step 2 - 4</h2>
                      </div>
                    </div>
                    <label className="fieldlabels">
                      Agency/Head Office Address *
                    </label>
                    <input
                      type="text"
                      name="agencyHeadOfficeAddress"
                      placeholder="Agency/Head Office Address"
                      onChange={handleFormChange}
                      value={formValues.agencyHeadOfficeAddress}
                    />
                    <label className="fieldlabels">No of Branches *</label>
                    <input type="number" name="noOfBranches" placeholder="No of Branches"  onChange={handleFormChange} value={formValues.noOfBranches} />
                    <label className="fieldlabels">Country *</label>
                    <input type="text" name="country" placeholder="Country" onChange={handleFormChange} value={formValues.country} />
                    <label className="fieldlabels">State *</label>
                    <input type="text" name="state" placeholder="State" onChange={handleFormChange} value={formValues.state} />
                    <label className="fieldlabels">City *</label>
                    <input type="text" name="city" placeholder="City"  onChange={handleFormChange} value={formValues.city}/>
                    <label className="fieldlabels">
                      Pincode/Zipcode (Optional)
                    </label>
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="Pincode/Zipcode"
                    />
                  </div>
                  <input
                    type="button"
                    name="next"
                    className="next action-button"
                    value="Next"
                    onClick={nextStep}
                  />
                  <input
                    type="button"
                    name="previous"
                    className="previous action-button-previous"
                    value="Previous"
                    onClick={prevStep}
                  />
                </fieldset>
              )}

              {/* Step 3: Contact Person Information */}
              {step === 3 && (
                <fieldset>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="fs-title">Contact Information</h2>
                      </div>
                      <div className="col-5">
                        <h2 className="steps">Step 3 - 4</h2>
                      </div>
                    </div>
                   
                    {/* <label className="fieldlabels">Email *</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleFormChange}
                      value={formValues.email}
                    /> */}
                    <label className="fieldlabels">Country Phone Code *</label>
                    <input
                      type="text"
                      name="countryPhoneCode"
                      placeholder="Country Phone Code"
                      onChange={handleFormChange}
                      value={formValues.countryPhoneCode}
                    />
                    <label className="fieldlabels">Contact No *</label>
                    <input
                      type="text"
                      name="contactNo"
                      placeholder="Contact No"
                      onChange={handleFormChange}
                      value={formValues.contactNo}
                    />
                  </div>
                  <input
                    type="button"
                    name="next"
                    className="next action-button"
                    value="Next"
                    onClick={nextStep}
                  />
                  <input
                    type="button"
                    name="previous"
                    className="previous action-button-previous"
                    value="Previous"
                    onClick={prevStep}
                  />
                </fieldset>
              )}

              {/* Step 4: Finish */}
              {step === 4 && (
                <fieldset>
                  <div className="form-card">
                    <h2 className="purple-text text-center">
                      <strong>Success!</strong>
                    </h2>
                    <p>You have successfully updated your profile.</p>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Finish"
                      onClick={handleSuccess} // Trigger modal when form is done
                    />
                  </div>
                </fieldset>
              )}

              {/* Include the SuccessModal */}
              <SuccessModal show={showModal} handleClose={handleCloseModal} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentUpdate;
