import React from 'react';
import { BiLock } from 'react-icons/bi';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaLightbulb, FaRibbon } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';
import { forwardRef } from 'react';

const HomeTwoFeatures =  forwardRef(( props,ref) => {
  return (
    <section className="features__area " ref={ref}>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="features__content-left">
              <div className="section__title section__title-h2 mb-25 fade-up ">
                <span>Shaping Education | Shaping Future</span>
                <h1> Explore Our Programs</h1>
              </div>
              <p>
                At MakeStudy, we go beyond networking. Our programs offer exclusive opportunities to grow and succeed. 
                From partnerships with leading educational institutions to providing agents with essential tools, we are here 
                to elevate your business and drive growth. Join us in making a meaningful impact.
              </p>

              {/* Add video directly below the paragraph */}
              <div className="video__wrapper mb-4">
  <video width="100%" autoPlay controls>
    <source src="assets/img/Webimg/3209298-sd_640_360_25fps (1).mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

              <Link to="/courses" className="z-btn">
                Explore More <i><CgArrowLongRight /></i>
              </Link>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="features__content-right">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                  <HomeTwoSingleFeature 
                    icon={<FaLightbulb />} 
                    title="UG Diplomas" 
                    description="UG Diplomas in Canada are 1 to 2-year practical programs offering career-focused education, often leading to work permits and pathways to permanent residency." 
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                  <HomeTwoSingleFeature 
                    icon={<BiLock />} 
                    title="UG Certificates" 
                    description="UG Certificates in Canada are short-term programs, usually 6 months to 1 year, focused on specific skills for quick entry into the job market or career advancement." 
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                  <HomeTwoSingleFeature 
                    icon={<FaRibbon />} 
                    title="PG Diplomas" 
                    description="Postgraduate (PG) Diplomas in Canada are 1 to 2-year programs that offer advanced, career-oriented education, combining theoretical knowledge with practical skills, often leading to enhanced job opportunities and eligibility for work permits."
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch">
                  <HomeTwoSingleFeature 
                    icon={<IoDocumentTextOutline />} 
                    title="Master degrees" 
                    description="Master’s degrees in Canada are advanced 1 to 2-year programs focusing on specialized academic or professional skills, enhancing career opportunities and preparing students for leadership roles or further research." 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HomeTwoFeatures;


