import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area  pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                <img src="/assets/img/about/about-1.jpg" alt="" />
                <div className="about__shape">
                  <img src="/assets/img/about/red-shape.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  {/* <span>Who we are</span> */}
                  <h2>We Unlock Potential</h2>
                </div>
                <p>
                  Your recuriment patner for indian subcontinent.our services
                  are avaoilable to the overseas educational institution.
                  <br />
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        360° student profiles
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Professional assistance
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Student marketPlace
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Educational Agent Portal
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Manage applications
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Wide rage of Masterst and Bachelor program
                      </span>
                    </li>
                  </ul>
                </div>
                <Link to="/services" className="z-btn ">
                  Learn More
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="brand__shape">
          <img
            className="shape triangle"
            src="/assets/img/icon/slider/triangle.png"
            alt="triangle"
          />
          <img
            className="shape dotted-square"
            src="/assets/img/icon/slider/dotted-square.png"
            alt="dotted-square"
          />
          <img
            className="shape solid-square"
            src="/assets/img/icon/slider/solid-square.png"
            alt="solid-square"
          />
          <img
            className="circle"
            src="/assets/img/icon/brand/circle.png"
            alt="Circle shape"
          />
          <img
            className="circle-2"
            src="/assets/img/icon/brand/circle-2.png"
            alt="Circle 2 shape"
          />
          <img
            className="triangle"
            src="/assets/img/icon/brand/trianlge.png"
            alt=""
          />
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
