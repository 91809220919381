import React, { useState } from 'react';
import axios from 'axios';
import './StudentPage.scss';

const StudentPage = () => {
  const [studentData, setStudentData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    maritalStatus: '',
    emergencyContact: '',
    email: '',
    fatherName: '',
    countryCode: '',
    phoneNumber: '',
    alternateNumber: '',
    motherName: '',
    nativeCountry: '',
    nativeState: '',
    nativeCity: '',
    address: '',
    pincode: '',
    education: {
      tenth: {
        class: '10th',
        yearPassed: '',
        subjects: '',
        board: '',
        percentage: '',
      },
      twelfth: {
        class: '12th',
        yearPassed: '',
        subjects: '',
        board: '',
        percentage: '',
      }
    },
    englishProficiency: {
      type: 'Not Required', // Default selected
      scores: {
        overall: '',
        listening: '',
        reading: '',
        writing: '',
        speaking: ''
      }
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData({ ...studentData, [name]: value });
  };

  const handleEducationChange = (level, field, value) => {
    setStudentData({
      ...studentData,
      education: {
        ...studentData.education,
        [level]: { ...studentData.education[level], [field]: value }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:3000/students', studentData)
      .then((response) => {
        console.log('Student added:', response.data);
      })
      .catch((error) => {
        console.error('Error adding student:', error);
      });
  };

  return (
    <form className="studentForm" onSubmit={handleSubmit}>
  <h2>Personal Information</h2>
  
  <label htmlFor="firstName">First Name</label>
  <input type="text" name="firstName" id="firstName" placeholder="Enter First Name" value={studentData.firstName} onChange={handleChange} required />

  <label htmlFor="lastName">Last Name</label>
  <input type="text" name="lastName" id="lastName" placeholder="Enter Last Name" value={studentData.lastName} onChange={handleChange} required />

  <label htmlFor="dob">Date of Birth</label>
  <input type="date" name="dob" id="dob" placeholder="Select Date of Birth" value={studentData.dob} onChange={handleChange} required />

  <label htmlFor="gender">Gender</label>
  <select name="gender" id="gender" value={studentData.gender} onChange={handleChange}>
    <option value="" disabled>Select Gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Other">Other</option>
  </select>

  <label htmlFor="maritalStatus">Marital Status</label>
  <select name="maritalStatus" id="maritalStatus" value={studentData.maritalStatus} onChange={handleChange}>
    <option value="" disabled>Select Marital Status</option>
    <option value="Single">Single</option>
    <option value="Married">Married</option>
    <option value="Divorced">Divorced</option>
    <option value="Other">Other</option>
  </select>

  <h2>Contact Information</h2>

  <label htmlFor="email">Email Address</label>
  <input type="text" name="email" id="email" placeholder="Enter Email Address" value={studentData.email} onChange={handleChange} required />

  <label htmlFor="emergencyContact">Emergency Contact Number</label>
  <input type="text" name="emergencyContact" id="emergencyContact" placeholder="Enter Emergency Contact Number" value={studentData.emergencyContact} onChange={handleChange} />

  <label htmlFor="phoneNumber">Phone Number</label>
  <input type="text" name="phoneNumber" id="phoneNumber" placeholder="Enter Phone Number" value={studentData.phoneNumber} onChange={handleChange} required />

  <label htmlFor="alternateNumber">Alternate Phone Number</label>
  <input type="text" name="alternateNumber" id="alternateNumber" placeholder="Enter Alternate Phone Number" value={studentData.alternateNumber} onChange={handleChange} />

  <h2>Parents Information</h2>

  <label htmlFor="fatherName">Father's Name</label>
  <input type="text" name="fatherName" id="fatherName" placeholder="Enter Father's Name" value={studentData.fatherName} onChange={handleChange} />

  <label htmlFor="motherName">Mother's Name</label>
  <input type="text" name="motherName" id="motherName" placeholder="Enter Mother's Name" value={studentData.motherName} onChange={handleChange} />

  <h2>Address</h2>

  <label htmlFor="nativeCountry">Country</label>
  <input type="text" name="nativeCountry" id="nativeCountry" placeholder="Enter Country" value={studentData.nativeCountry} onChange={handleChange} />

  <label htmlFor="nativeState">State/Province</label>
  <input type="text" name="nativeState" id="nativeState" placeholder="Enter State/Province" value={studentData.nativeState} onChange={handleChange} />

  <label htmlFor="nativeCity">City</label>
  <input type="text" name="nativeCity" id="nativeCity" placeholder="Enter City" value={studentData.nativeCity} onChange={handleChange} />

  <label htmlFor="address">Complete Address</label>
  <textarea name="address" id="address" placeholder="Enter Complete Address" value={studentData.address} onChange={handleChange} />

  <label htmlFor="pincode">Pincode</label>
  <input type="text" name="pincode" id="pincode" placeholder="Enter Pincode" value={studentData.pincode} onChange={handleChange} />

  <h2>Education Details</h2>

  <div className="education-block">
    <h3>10th / Secondary</h3>
    
    <label htmlFor="tenthYear">Year Passed</label>
    <input type="text" name="tenthYear" id="tenthYear" placeholder="Enter Year Passed" value={studentData.education.tenth.yearPassed} onChange={(e) => handleEducationChange('tenth', 'yearPassed', e.target.value)} />

    <label htmlFor="tenthSubjects">Subjects/Stream</label>
    <input type="text" name="tenthSubjects" id="tenthSubjects" placeholder="Enter Subjects/Stream" value={studentData.education.tenth.subjects} onChange={(e) => handleEducationChange('tenth', 'subjects', e.target.value)} />

    <label htmlFor="tenthBoard">Board</label>
    <input type="text" name="tenthBoard" id="tenthBoard" placeholder="Enter Board" value={studentData.education.tenth.board} onChange={(e) => handleEducationChange('tenth', 'board', e.target.value)} />

    <label htmlFor="tenthPercentage">Percentage/CGPA</label>
    <input type="text" name="tenthPercentage" id="tenthPercentage" placeholder="Enter Percentage/CGPA" value={studentData.education.tenth.percentage} onChange={(e) => handleEducationChange('tenth', 'percentage', e.target.value)} />
  </div>

  <div className="education-block">
    <h3>12th / Senior Secondary</h3>
    
    <label htmlFor="twelfthYear">Year Passed</label>
    <input type="text" name="twelfthYear" id="twelfthYear" placeholder="Enter Year Passed" value={studentData.education.twelfth.yearPassed} onChange={(e) => handleEducationChange('twelfth', 'yearPassed', e.target.value)} />

    <label htmlFor="twelfthSubjects">Subjects/Stream</label>
    <input type="text" name="twelfthSubjects" id="twelfthSubjects" placeholder="Enter Subjects/Stream" value={studentData.education.twelfth.subjects} onChange={(e) => handleEducationChange('twelfth', 'subjects', e.target.value)} />

    <label htmlFor="twelfthBoard">Board</label>
    <input type="text" name="twelfthBoard" id="twelfthBoard" placeholder="Enter Board" value={studentData.education.twelfth.board} onChange={(e) => handleEducationChange('twelfth', 'board', e.target.value)} />

    <label htmlFor="twelfthPercentage">Percentage/CGPA</label>
    <input type="text" name="twelfthPercentage" id="twelfthPercentage" placeholder="Enter Percentage/CGPA" value={studentData.education.twelfth.percentage} onChange={(e) => handleEducationChange('twelfth', 'percentage', e.target.value)} />
  </div>

  <h2>English Proficiency</h2>
  
  <label htmlFor="englishProficiencyType">Proficiency Type</label>
  <select name="englishProficiencyType" id="englishProficiencyType" value={studentData.englishProficiency.type} onChange={(e) => handleChange(e)}>
    <option value="" disabled>Select Proficiency Type</option>
    <option value="Not Required">Not Required</option>
    <option value="IELTS">IELTS</option>
    <option value="PTE">PTE</option>
    <option value="TOEFL">TOEFL</option>
    <option value="CAEL">CAEL</option>
  </select>

  {studentData.englishProficiency.type !== 'Not Required' && (
    <div className="english-scores">
      <label htmlFor="overallScore">Overall Score</label>
      <input type="text" name="overallScore" id="overallScore" placeholder="Enter Overall Score" value={studentData.englishProficiency.scores.overall} onChange={handleChange} />

      <label htmlFor="listeningScore">Listening Score</label>
      <input type="text" name="listeningScore" id="listeningScore" placeholder="Enter Listening Score" value={studentData.englishProficiency.scores.listening} onChange={handleChange} />

      <label htmlFor="readingScore">Reading Score</label>
      <input type="text" name="readingScore" id="readingScore" placeholder="Enter Reading Score" value={studentData.englishProficiency.scores.reading} onChange={handleChange} />

      <label htmlFor="writingScore">Writing Score</label>
      <input type="text" name="writingScore" id="writingScore" placeholder="Enter Writing Score" value={studentData.englishProficiency.scores.writing} onChange={handleChange} />

      <label htmlFor="speakingScore">Speaking Score</label>
      <input type="text" name="speakingScore" id="speakingScore" placeholder="Enter Speaking Score" value={studentData.englishProficiency.scores.speaking} onChange={handleChange} />
    </div>
  )}

  <button type="submit">Submit</button>
</form>

  );
};

export default StudentPage;
