import React from 'react';
import SingleCount from '../../../components/SingleCount/SingleCount';
import { FaUniversity, FaUsers, FaUserFriends, FaBook } from 'react-icons/fa'; // Import icons from react-icons or use your own images
import { forwardRef } from 'react';
const iconSize = 30; // Adjust the size of icons here

const iconStyles = {
    blue: { color: '#007bff' }, // Example color for blue-2-color
    pink: { color: '#ff4081' }, // Example color for pink-color
    green: { color: '#4caf50' }, // Example color for green-4-color
    orange: { color: '#ff5722' }, // Example color for orange-color
};

const HomeTwoCounter = forwardRef(( props,ref)=> {
    return (
        <section className="counter__area counter__area-2 pt-85 pb-85" ref={ref}>
            <div className="container">
                <div className="heading-section mb-5  fade-up">
                    <h1 >Join 500+ Global Recruitment Partners</h1>
                    {/* <button className="z-btn">Partner with Us</button> */}
                </div>
                <br/>
                <div className="row fade-up">
                    <div className="col-md-3 text-center">
                        <FaUniversity size={iconSize} style={iconStyles.blue} className="icon" />
                        <SingleCount counter={15} title="Institutions" color="blue-2-color" />
                    </div>
                    <div className="col-md-3 text-center">
                        <FaUsers size={iconSize} style={iconStyles.pink} className="icon" />
                        <SingleCount counter={9868} title="Students" color="pink-color" />
                    </div>
                    <div className="col-md-3 text-center">
                        <FaUserFriends size={iconSize} style={iconStyles.green} className="icon" />
                        <SingleCount counter={526} title="Recruitment Partner" color="green-4-color" />
                    </div>
                    <div className="col-md-3 text-center">
                        <FaBook size={iconSize} style={iconStyles.orange} className="icon" />
                        <SingleCount counter={1021} title="Study Programs" color="orange-color" />
                    </div>
                </div>
            </div>
        </section>
    );
});

export default HomeTwoCounter;
