import React from "react";
import { Link } from "react-router-dom";

const HomeThreeFaq = () => {
  return (
    <>
      <section className="faq__area pb-200 pt-75">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-5">
              <div className="faq__content">
                <div className="section-title mb-45">
                  <h2>
                    Do you have <br /> Any question
                  </h2>
                  <p>
                    We help you weather today's uncertainty through our best
                    team.
                  </p>
                </div>
                <Link to="/about" className="z-btn z-btn-border">
                  All Question
                </Link>
                <img
                  src="/assets/img/Webimg/Shutterstock.jpg" // Use forward slashes for the correct path
                  alt="Shutterstock" // Always good to include alt for accessibility
                  style={{ marginTop: "20px", width: "100%", height: "auto" }} // Adjust the styling as needed
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
              <div className="faq__accordion p-relative">
                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="card-header accordion-header" id="acc_1">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_1"
                          aria-expanded="true"
                          aria-controls="collapse_1"
                        >
                          What services do you offer?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_1"
                      className="collapse show"
                      aria-labelledby="acc_1"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          We provide expert admission services to help students
                          secure spots in our prestigious partner colleges and
                          universities in Canada and the USA. From personalized
                          guidance in selecting the right program to seamless
                          application support, we ensure a smooth transition
                          into some of the top educational institutions abroad.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_2">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_2"
                          aria-expanded="true"
                          aria-controls="collapse_2"
                        >
                          Where are your colleges located in Canada?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_2"
                      className="collapse"
                      aria-labelledby="acc_2"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Our partner colleges are located across Canada,
                          covering major cities and provinces, including
                          Ontario, British Columbia, Alberta, Quebec, and more.
                          Whether you’re looking for urban or suburban campuses,
                          we have options that cater to diverse preferences and
                          academic goals. Contact us for detailed information on
                          specific locations and programs available.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_3">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_3"
                          aria-expanded="true"
                          aria-controls="collapse_3"
                        >
                          Are your partner colleges in Canada eligible for a
                          Post-Graduation Work Permit (PGWP)?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_3"
                      className="collapse"
                      aria-labelledby="acc_3"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Yes, all programs offered through our partner colleges
                          in Canada are eligible for a Post-Graduation Work
                          Permit (PGWP) of up to 3 years. This allows students
                          to gain valuable Canadian work experience after
                          completing their studies, making it an excellent
                          pathway to future career opportunities and permanent
                          residency in Canada.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_4">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_4"
                          aria-expanded="true"
                          aria-controls="collapse_4"
                        >
                          What is the lowest tuition fee at your partner
                          colleges?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_4"
                      className="collapse"
                      aria-labelledby="acc_4"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Our yearly tuition fees start as low as $10,000 CAD
                          for master's degree programs. We offer a range of
                          affordable options to suit different budgets without
                          compromising on quality education. Contact us for the
                          latest updates and specific program details.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_5">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_5"
                          aria-expanded="true"
                          aria-controls="collapse_5"
                        >
                          Do you offer scholarships or discounts?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_5"
                      className="collapse"
                      aria-labelledby="acc_5"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          {" "}
                          Yes, we offer scholarships and discounts based on
                          academic qualifications, achievements, and specific
                          program eligibility. These financial aids are designed
                          to support deserving students in reducing their
                          tuition costs. Contact us to learn more about the
                          criteria and how you can qualify.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_6">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_6"
                          aria-expanded="true"
                          aria-controls="collapse_6"
                        >
                          Which intakes are currently available in Canada?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_6"
                      className="collapse"
                      aria-labelledby="acc_6"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          {" "}
                          Our partner colleges offer multiple intakes throughout
                          the year, including January 2025, May 2025, and
                          September 2025. These flexible start dates allow
                          students to begin their studies at a time that best
                          suits their schedule. Contact us for specific program
                          availability and deadlines.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_7">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_7"
                          aria-expanded="true"
                          aria-controls="collapse_7"
                        >
                          Do you charge any service fee in advance?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_7"
                      className="collapse"
                      aria-labelledby="acc_7"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          {" "}
                          No, we do not charge any service fees in advance. Our
                          priority is to provide transparent and reliable
                          services without upfront costs, ensuring a hassle-free
                          experience for students. You can trust us to guide you
                          through the process with no hidden fees.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_8">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_8"
                          aria-expanded="true"
                          aria-controls="collapse_8"
                        >
                          How long has your company been in operation, and are
                          you a licensed agent?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_8"
                      className="collapse"
                      aria-labelledby="acc_8"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          {" "}
                          We have been proudly serving students for over 23
                          years as a licensed and reputable consultancy. Our
                          license, No. 41/MC1/MA, is issued in Jalandhar,
                          Punjab, reflecting our commitment to professionalism
                          and compliance with industry standards. With decades
                          of experience, we are a trusted partner in your
                          educational journey.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_9">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_9"
                          aria-expanded="true"
                          aria-controls="collapse_9"
                        >
                          How can we contact you for partnership opportunities?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_9"
                      className="collapse"
                      aria-labelledby="acc_9"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          You can reach out to us by emailing
                          agents@makestudy.com. Alternatively, you can register
                          as a partner agency directly through the link
                          available on our website. Join our network and
                          collaborate with us to expand your reach and
                          opportunities in the education sector.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeThreeFaq;
