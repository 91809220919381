import React from 'react'
import './AgentArea.scss'
import { FaCheckCircle } from 'react-icons/fa'

const AgentArea = () => {
  return (
    <div className="success-page">
    {/* Main Success Content */}
   
    <div className="success-message">
      
      <FaCheckCircle size={80} className="icon" />
      <h2 className="animated-title">Profile Successfully Updated</h2>
      <p className="animated-text">
        You have successfully updated your profile.
        <br />
        To activate your account, please contact us at:
      </p>
      <div className="contact-details animated-contact">
        <p><strong>Phone:</strong> +91-96581-96581</p>
        <p><strong>WhatsApp:</strong> +91-98759-01624, +91-98759-01625</p>
        <p><strong>Email:</strong> agents@makestudy.com, p.singh@makestudy.com</p>
      </div>
    </div>
  </div>
  )
}

export default AgentArea
