import React, { useState } from 'react';
import './HomeTwoHeroSlider.scss';

const HomeTwoHeroSlider = ({openLoginModal}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="video-area">
      <div className="video-wrapper">
        <video
          className="video-background"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="\assets\img\Webimg\homevedio.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay">
          <div className="video-content">
            <h1 className="">
            Your Success | Our Passion.<br/>
             Your Vision | Our Priority
          </h1><p> 
            Your accomplishments are not just important to us; they are a driving force <br/>behind everything we do. We prioritize understanding and aligning with your vision<br/> to ensure that our services and strategies are tailored to meet your unique objectives.
          </p>
            <span
              className={`button ${isHovered ? 'hover' : ''}` }
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={()=>openLoginModal(true)}
            >
              Become a Partner
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTwoHeroSlider;
