import { useEffect, useRef } from 'react';

const useAnimationOnScroll = (animationClass) => {
   const elementRef = useRef(null);

   useEffect(() => {
      const handleScroll = () => {
         if (elementRef.current) {
            // Add the animation class when element is in view
            const rect = elementRef.current.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
               elementRef.current.classList.add(animationClass);
            }
         }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, [animationClass]);

   return elementRef;
};

export default useAnimationOnScroll;